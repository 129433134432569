import React, { useEffect, useState } from 'react';
import "../css/Dashboard.css";
import Card from '../Componenets/Card';
import moneybag from "../assets/money-bag.png";
import customer from "../assets/customer.png";
import team from "../assets/team.png";
import cart from "../assets/cart.png";
import { RiArrowUpFill,RiArrowDownFill  } from "react-icons/ri";
import AreaGraph from '../Graphs/AreaGraph';
import BarGraph from '../Graphs/BarGraph';
import PieDonutChart from '../Graphs/PieDonutChart';
import Header from "../Componenets/Header";
import { getDashboardData, getTransaction, getUserWeekly, salesOverview } from '../Path/Path';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Componenets/Loader';

const Dashboard = () => {

    const[salesData,setSalesData] = useState([]);
    const[usersData,setUsersData] = useState([]);
    const [dashboard,setDashboard] = useState({});
    const [totalEarning,setTotalEarning] = useState([]);
    const[loading,setLoading] = useState(false);
    const[per,setPer] = useState("");
    const[userPer,setUserPer] = useState("");
    const userPerInString = userPer.toString();
    const userPerSet = userPerInString.split(".")[0];
    const perInString = per.toString();

    useEffect(()=>{
        getData()
    },[])

   

    const getData = async () =>{
        setLoading(true)
        await getDashboardData().then((res)=>{
            setDashboard(res)
            setLoading(false)
        }).catch((err)=>{
            setLoading(false)
            toast.error("Something went wrong ",err.message)
        })

        setLoading(true)
        await salesOverview().then((res)=>{
            setSalesData(res)
            setPer(res.difference)
            setLoading(false)
        }).catch((err)=>{
            setLoading(false)
            toast.error("Something went wrong ",err.message)
        })

        setLoading(true)
        await getUserWeekly().then((res)=>{
            setUsersData(res)
            setUserPer(res.difference)
            setLoading(false)
        }).catch((err)=>{
            setLoading(false)
            toast.error("Something went wrong ",err.message)
        })

        setLoading(true)
        await getTransaction().then((res)=>{
            setTotalEarning(res.docs)
            setLoading(false)
        }).catch((err)=>{
            setLoading(false)
            toast.error("Something went wrong", err.message)
        })
    }

    

    return (<React.Fragment>
        <Header head={"Dashboard"} paraDash={"Get summary of your weekly Hermoso Store Transactions here."}/>
        <ToastContainer/>
        <Loader loading={loading}/>
        <div className='main-div'>
            <div className='dashboard-top'>
                <Card head="Today's Profit" num={dashboard.todaysProfit} span="+55%" source={moneybag} />
                <Card head="Today's Customers" num={dashboard.todaysCustomers} span="+20%" source={customer} />
                <Card head="New Customers" num={dashboard.newCustomers} span="-2%" source={team} />
                <Card head="Sales" num={dashboard.totalSales} span="+5%" source={cart} />
            </div>
            <div className='dashboard-area'>
                <p className='area-para'>Sales Overview</p>
                <div className='sale-flex'>
                    <div className='up-down-arrow'>{perInString.includes("-")? <RiArrowDownFill style={{color:"#EA0606"}} /> : <RiArrowUpFill />}</div>
                    <p className='area-progress'>{perInString}% {perInString.includes("-")? "less" : "more"} in 2022</p>
                </div>
                <div className='area-graph'>
                    <AreaGraph myData={salesData} />
                </div>
            </div>
            <div className='bottom-flex'>
                <div className='dashboard-footer'>
                    <div className='bar-progress'>
                        <p className='active'>Active Customers</p>
                        <p className='active-percent'> <span style={{ color: "#67748E" }}>({userPerSet}%) </span> than last week</p>
                    </div>
                    <div className='bar-graph'>
                        <BarGraph userData={usersData} />
                    </div>
                </div>
                <div className='dashboard-footer-bottom'>
                    <p className='pie-para'>Earning Chart</p>
                    <div className='withdraw'>
                        {/* <p className='with'>Withdraw Amount</p> */}
                        <p className='total'>Total Amount</p>
                    </div>
                    <div className='pie-flex'>
                        <div className='pie-donut'>
                            <PieDonutChart myPieData={totalEarning} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    )
}

export default Dashboard;
