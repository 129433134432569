import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import "../css/Modal.css";
import DropDown from './DropDown';
import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";

const Modal = ({
    open,
    onClick,
    getStatuspro,
    getUserNamepro,
    getAccountTypepro,
    onUpdate,
    accountType,
    userName,
    status,
    asignRole,
    onClose,
    getRolesStatus,
    roles,
    email,
    checkService,
}) => {



    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", alignItems: "center" }}>
                {
                    window.location.pathname === "/accounts" ?
                        <h2 className='h2-head' style={{ fontSize: "2.5rem" }}>Edit User</h2>
                        :
                        window.location.pathname === "/assignRoles" ?
                            <h2 className='h2-head' style={{ fontSize: "2.5rem" }}>Edit Role</h2>
                            : null
                }
                <div className='close-btn' style={{ margin: "-10px", fontSize: "3rem", cursor: "pointer" }} onClick={onClose}><TiDelete /></div>
            </div>
            <div className='line'></div>
            <DialogContent>
                <div className="modal-main" style={{ width: "500px" }} >
                    <label htmlFor='username'>{checkService === 'service provider' ? 'Store Name' : 'Username'}</label>
                    <input type="text" value={userName} id='username' placeholder={checkService === 'service provider' ? 'Store name' : 'Username'} onChange={(e) => getUserNamepro(e.target.value)} />
                </div>
                {
                    !asignRole ?
                        <div>
                            <div className='drop'>
                                <label>Acctount Type</label>
                                <DropDown
                                    options={[
                                        { label: 'Customer', value: 'customer' },
                                        { label: 'Service Provider', value: 'service provider' },
                                    ]}
                                    value={accountType}
                                    onChange={(e) => getAccountTypepro(e.target.value)}
                                />
                            </div>
                            <div className='drop' style={{ marginTop: "-20px" }}>
                                <label>Status</label>
                                <DropDown
                                    options={[
                                        { label: 'Approved', value: 'Approved' },
                                        { label: 'Pending', value: 'Pending' },
                                    ]}
                                    value={status}
                                    onChange={(e) => getStatuspro(e.target.value)}
                                />
                            </div>
                        </div>
                        :
                        <div className="modal-main">
                            <label htmlFor='email'>Email</label>
                            <input type="text" id='email' disabled value={email} placeholder='Email' />
                            <div>
                                <div className='drop'>
                                    <label>Status</label>
                                    <DropDown
                                        options={[
                                            { label: 'Admin', value: 'admin' },
                                            { label: 'Financial Manager', value: 'financial manager' },
                                            { label: 'Inspection Manager', value: 'inspection manager' },
                                        ]}
                                        value={roles}
                                        onChange={(e) => getRolesStatus(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                }
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClose}>Cancel</button>
                </div>
                <div className='save-changes'>
                    <button onClick={onUpdate}>Save Changes</button>
                </div>
            </div>
        </Dialog>
    )
}

export default Modal;