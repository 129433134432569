import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";



const BarCharts = ({moneyEar}) => {

    const [data,setData] = useState([]);
    const DATE_FORMAT = "MMM"

    useEffect(()=>{
        getData()
    },[moneyEar])

    const getData = async () =>{
        const newData = Array(12).fill(moment().format(DATE_FORMAT)).reduce((acc,ite,i)=>{
            const name = moment().subtract(i,"month").format(DATE_FORMAT)
            acc.push({
                name:name,
                arrival: moneyEar?.storeEarn?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc,item)=> acc + item.totalPrice ,0),
                spending: moneyEar?.hermosoEarn?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc,item)=> acc + item.total ,0)
            })

            return acc;
        },[])

        setData(newData.reverse())
    } 

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                data={data}
                margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 10
                }}
            >
                <CartesianGrid strokeDasharray="1 0" vertical={false} />
                <XAxis dataKey="name" tickLine={false} dy={10} interval={0} stroke="#25233A" />
                <YAxis
                    hide
                />
                <Tooltip cursor={{ fill: "transparent" }} />
                <Bar dataKey="arrival" fill="#0CA592" barSize={20} />
                <Bar dataKey="spending" fill="#2A5555" barSize={20} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default BarCharts;