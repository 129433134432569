import React, { useEffect, useState } from 'react';
import Header from '../Componenets/Header';
import "../css/ServiceProvider.css";
import profilePerson from "../assets/profile.jpg";
import Profile from '../Componenets/Profile';
import TotalBokingChart from '../Graphs/TotalBokingChart';
import RunWaitChart from '../Graphs/RunWaitChart';
import data, { data2 } from './StaticData';
import { GrStatusGood } from "react-icons/gr";
import { TiDeleteOutline } from "react-icons/ti";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import SaloonRatingChart from '../Graphs/SaloonRatingChart';
import DropDown from '../Componenets/DropDown';
import { FaSlidersH } from "react-icons/fa";
import ProgressBar from "@ramonak/react-progress-bar";
import women from "../assets/women.jpg";
import lips from "../assets/lips.jpg";
import nail from "../assets/nail.jpg";
import supplemet from "../assets/supplments.jpg";
import swal from "sweetalert";
import Modal from '../Componenets/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { addService, editBooking, getAllStore, getAllUsers, getBookingData, getBookingRunWait, getCategory, getListing, getSaloon, saloonRating } from '../Path/Path';
import Loader from '../Componenets/Loader';
import moment from 'moment';
import ServiceAddModel from '../Componenets/ServiceAddModel';

const ServiceProviders = () => {

  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState();
  const [waitingBooking, setWaitingBooking] = useState();
  const [saloonRatingData, setSaloonRatingData] = useState();
  const [profileId, setProfileId] = useState("");
  console.log('profileId: ', profileId);
  const [listingData, setListingData] = useState();
  const [toggle, setToggle] = useState(true);
  const [type, setType] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState();
  const [images, setImages] = useState([]);
  const [statues, setStatues] = useState(true);
  const [categoriesData, setCategoriesData] = useState([])
  const [catego, setCatego] = useState("6339b79c051608df8edfd8be")
  console.log('catego: ', catego);
  const sta = Number(statues)
  const realStatus = Boolean(sta);

  useEffect(() => {
    categoryData();
    getProfiles();
  }, [])



  const categoryData = async () => {
    setLoading(true)
    await getCategory().then((res) => {
      setCategoriesData(res)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong", err.message)
    })
  }


  const getProfiles = async () => {
    setLoading(true);
    const data = await getAllStore().then((res) => {
      if (res.length > 0) {
        let response = res.filter((i) => i.isActive === true);
        setProfile(response)
        setLoading(false)

      } else {
        toast.warn("No data found!");
        setLoading(false)
      }
    }).catch((err) => {
      toast.error("Something went wrong!");
      setLoading(false)
    })
  }





  const [visible, setVisible] = useState(false);

  const modalHandler = () => {
    if (profileId) {
      setVisible(true)
    }
    else {
      toast.warn('Please select store')
    }

  }

  const approvedHandler = (id) => {
    const body = {
      status: "approved"
    }

    swal({
      title: "Are you sure?",
      text: "Are you sure to complete this request?",
      icon: "warning",
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          await editBooking(body, id).then((res) => {
            toast.success("Booking edit successfully.")
            getBookingRunWait(profileId).then((res) => {
              setWaitingBooking(res.docs)
            }).catch((err) => {
              toast.error("Waiting Booking Data No Found!");
            })
            setLoading(false)
          }).catch((err) => {
            toast.warn("Booking cannot be edit!")
          })
          swal("Approved!", "Approved successfully ", "success");
        }
      })
  }


  const disApprovedHandler = (id) => {
    const body = {
      status: "cancelled"
    }
    swal({
      title: "Are you sure?",
      text: "Are you sure to cancel this request?",
      icon: "warning",
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          await editBooking(body, id).then((res) => {
            toast.success("Booking edit successfully.")
            getBookingRunWait(profileId).then((res) => {
              setWaitingBooking(res.docs)
            }).catch((err) => {
              toast.error("Waiting Booking Data No Found!");
            })
            setLoading(false)
          })
          swal("Cancel!", "Cancel successfully ", "success");
        }
      })
  }



  const profileData = async (item) => {
    setProfileId(item._id)
    setLoading(true)
    await getBookingData(item._id).then((res) => {
      setBookingData(res)
    }).catch((err) => {
      toast.error("Something went wrong!");
    })

    await getBookingRunWait(item._id).then((res) => {
      setWaitingBooking(res.docs)
    }).catch((err) => {
      toast.error("Something went wrong!");
    })

    await saloonRating(item._id).then((res) => {
      setSaloonRatingData(res)
    }).catch((err) => {
      toast.error("Something went wrong!");
    })

    await getListing(item._id).then((res) => {
      if (res.length > 0) {
        res = res?.filter((item, index) => item?.isActive === type)
        setListingData(res)
        setLoading(false)
      } else {
        setLoading(false)
        toast.warn("No data found!");
      }
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong!");
    })

    setLoading(false)
  }

  console.log("list", listingData);

  const listing = async (type) => {
    setLoading(true)
    await getListing(profileId).then((res) => {
      if (res.length > 0) {
        let response = res?.filter((item, index) => item?.isActive === type)
        console.log('button Res: ', res);
        setListingData(response)
        setLoading(false)
      } else {
        setLoading(false)
        toast.warn("No data found!");
      }
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong!");
    })
    setLoading(false)

  }



  const activeToggle = async () => {
    setToggle(true)
    setType(true)
    if (profileId) {
      await listing(true)
    }
    else {
      toast.warn('Please select store')
    }
  }

  const deactiveToggle = async () => {
    setToggle(false)
    setType(false)
    if (profileId) {
      await listing(false)
    }
    else {
      toast.warn('Please select store')
    }
  }

  console.log("type", type);

  const sendHandler = async (e) => {
    let body = {
      name: name,
      description: description,
      price: Number(price),
      storeID: profileId,
      images: e,
      isActive: realStatus,
      categoryID: catego,
      storeID: profileId
    }

    setLoading(true)
    await addService(body).then((res) => {
      setVisible(false)
      toast.success("Listing created successfully.")
      setLoading(false)
      setName("")
      setDescription("")
      setPrice("")
      setStatues(true)
      setCatego("")
      setImages("")
    }).catch((err) => {
      setImages([])
      toast.error("Something went wrong ", err.message)
      setVisible(false)
      setLoading(false)
    })


  }

  console.log("waiting", waitingBooking);

  return (
    <React.Fragment>
      <Header head={"Service Providers"} placeholder={"Search by saloon name"} search getSearchValue={(value) => setSearchVal(value)} paraDash={"Get all information about service providers."} />
      <ToastContainer />
      <Loader loading={loading} />
      <div className='main-div'>
        <div className='service-area'>
          <p className='area-para'>Profile</p>
          <div className='line'></div>
          <div className='profiles-scrol'>
            {
              profile?.filter((val) => {
                if (searchVal == "") {
                  return val
                } else if (val?.name?.toUpperCase()?.includes(searchVal.toUpperCase())) {
                  return val
                }
              }).map((item) =>
                <Profile
                  imgPro={item.gallery[0]}
                  profileName={item.name}
                  profileMail={item.about}
                  onClick={() => profileData(item)}
                />
              )
            }
          </div>
        </div>
        <div className='total-booking-flex'>
          <div className='total-flex'>
            <p className='area-para'>Total Booking</p>
            <div className='this-last-mont'>
              <p className='this'>This Month</p>
              <p className='last'>Last Month</p>
            </div>
          </div>
          <div className='total-chart'>
            <TotalBokingChart myData={bookingData} />
          </div>
        </div>
        <div className='total-booking-flex1'>
          <div className='total-flex'>
            <p className='area-para'>Waiting Booking</p>
            <div className='this-last-mont'>
              <p className='wait'>Waiting</p>
            </div>
          </div>
          <div className='total-chart1'>
            <RunWaitChart runData={waitingBooking} />
          </div>
        </div>
        <div className='table-saloon-flex'>
          <div className='saloon-raing'>
            <div className='saloon-rating-flex'>
              <p className='area-para'>Saloon Ratings</p>
              <div className='this-last-month'>
                <p className='this-month'>This Month</p>
                <p className='last-month'>Last Month</p>
              </div>
            </div>
            <div className='saloon-ration-chart'>
              <SaloonRatingChart saloonData={saloonRatingData} />
            </div>
          </div>
        </div>
        <div className='flex-res-reports'>
          <div className='registration-req1'>
            <div className='ris'>
              <div className='reqs-ta1'><p className='area-para'>Bookings</p></div>
              <div className='ris-table'>
                <table className='ris-tables'>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Total Price</th>
                      <th>Hermose Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {waitingBooking?.reverse().map((elem, i) =>
                      <tr key={i}>
                        <td style={{ width: "200px" }}>{elem?.userID?.userName?.substring(0, 7).concat("...")}</td>
                        <td style={{ width: "200px" }}>{elem?.userID?.type}</td>
                        <td style={{ width: "200px" }}>{moment(elem?.createdAt).format("D MMM YYYY")}</td>
                        <td style={{ width: "200px" }}><p className={elem?.status === "completed" ? "select" : elem?.status === "pending" ? "select1" : elem?.status === "cancelled" ? "select2" : null}>{elem?.status === "completed" ? "Completed" : elem?.status === "pending" ? "Pending" : elem.status === "cancelled" ? "Cancel" : null}</p></td>
                        <td style={{ width: "200px" }}>{elem?.totalPrice}</td>
                        <td style={{ width: "200px" }}>{elem?.totalPrice * 30 / 100}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='pro-flex-bar'>
          <div className='registration-req1'>
            <div className='ris'>
              <div className='reqs-ta4'>
                <p className='area-para'>Listing</p>
                <div className='listong-icons'>
                  {/* <div className='slider'><FaSlidersH /></div> */}
                  <button type='text' className={toggle ? "first-btn-select" : "first-btn"} onClick={activeToggle}>Active Listing</button>
                  <button type='text' className={toggle === false ? "sec-select" : "sec"} onClick={deactiveToggle}>Deactive Listing</button>
                  <button type='text' className='third-btn' onClick={modalHandler}>Add Listing</button>
                </div>
              </div>
              <div className='ris-table'>
                <table className='ris-tables'>
                  <thead>
                    <tr>
                      <th>Store Name</th>
                      <th>Service Name</th>
                      <th>Date</th>
                      <th>Price</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listingData?.reverse().map((elem, i) =>
                      <tr>
                        <td>{elem?.storeID?.name}</td>
                        <td>{elem?.name}</td>
                        <td>{moment(elem?.createdAt).format("D-MMM-YYYY")}</td>
                        <td>{elem?.price}</td>
                        <td>
                          <p className={elem?.isActive === "true" ? "select" : elem?.status === false ? "select2" : "select"}>{elem?.isActive === true ? "Active" : "Deactive"}</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className='serve-div'>
            <p className='area-para'>Serve</p>
            <div className='progress-bars'>
              <div className='img-progress'>
                <img src={women} alt="" />
              </div>
              <div className='pro-bar'>
                <ProgressBar
                  completed={60}
                  labelAlignment="outside"
                  labelColor="#696969"
                  labelSize="24px"
                  height='10px'
                  bgColor="#F99C55"
                  width='100%'
                />
                <div className='pro-text'>Face Cosmetics</div>
              </div>
            </div>
            <div className='progress-bars'>
              <div className='img-progress1'>
                <img src={lips} alt="" />
              </div>
              <div className='pro-bar'>
                <ProgressBar
                  completed={21}
                  labelAlignment="outside"
                  labelColor="#696969"
                  labelSize="24px"
                  height='10px'
                  bgColor="#24AB4A"
                  width='100%'
                />
                <div className='pro-text'>Lips Cosmetics</div>
              </div>
            </div>
            <div className='progress-bars'>
              <div className='img-progress2'>
                <img src={nail} alt="" />
              </div>
              <div className='pro-bar'>
                <ProgressBar
                  completed={74}
                  labelAlignment="outside"
                  labelColor="#696969"
                  labelSize="24px"
                  height='10px'
                  bgColor="#70A6E8"
                  width='100%'
                />
                <div className='pro-text'>Nails Cosmetics</div>
              </div>
            </div>
            <div className='progress-bars'>
              <div className='img-progress3'>
                <img src={supplemet} alt="" />
              </div>
              <div className='pro-bar'>
                <ProgressBar
                  completed={40}
                  labelAlignment="outside"
                  labelColor="#696969"
                  labelSize="24px"
                  height='10px'
                  bgColor="#F298DE"
                  width='100%'
                />
                <div className='pro-text'>Supplements</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ServiceAddModel
        open={visible ? true : false}
        onClose={() => {
          setVisible(false)
          setName("")
          setDescription("")
          setPrice("")
          setStatues(true)
          setCatego("")
          setImages("")
        }}
        onUpdate={(e) => sendHandler(e)}
        getName={(v) => setName(v)}
        getDescription={(v) => setDescription(v)}
        getPrice={(v) => setPrice(v)}
        getStatus={(v) => setStatues(v)}
        getLinks={(v) => setImages(v)}
        name={name}
        description={description}
        price={price}
        statues={statues}
        profileId={profileId}
        categoriesData={categoriesData}
        getCate={(v) => setCatego(v)}
        catego={catego}
      />
    </React.Fragment>
  )
}

export default ServiceProviders;
