import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { getAllSaloonSales } from "../Path/Path";

// const data = [
//     {
//         name: "Jan",
//         uv: 4000,
//         pv: 2400,
//         amt: 2400
//     },
//     {
//         name: "Feb",
//         uv: 3000,
//         pv: 1398,
//         amt: 2210
//     },
//     {
//         name: "Mar",
//         uv: 2000,
//         pv: 9800,
//         amt: 2290
//     },
//     {
//         name: "Apr",
//         uv: 2780,
//         pv: 3908,
//         amt: 2000
//     },
//     {
//         name: "May",
//         uv: 1890,
//         pv: 4800,
//         amt: 2181
//     },
//     {
//         name: "Jun",
//         uv: 2390,
//         pv: 3800,
//         amt: 2500
//     },
//     {
//         name: "Jul",
//         uv: 3490,
//         pv: 4300,
//         amt: 2100
//     }
// ];

const ServiceProviderChart = ({myPrvider}) => {


    const [data, setData] = useState([]);
    const DATE_FORMAT = "Do"

    useEffect(() => {
        getData()
    }, [myPrvider])

    const getData = async () => {

        const newData = Array(30).fill(moment().format(DATE_FORMAT)).reduce((acc, item, i) => {
            const name = moment().subtract(i, "days").format(DATE_FORMAT);
            acc.push({
                name: name,
                This_Month: myPrvider?.currentMonth?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).length,
                Last_Month: myPrvider?.previousMonth?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).length
            })
            return acc;
        }, [])

        setData(newData.reverse());
    }




    return (
        <div className="service-sales-chart">
            <ToastContainer/>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 10,
                        bottom: 0
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="30%" stopColor=" #F3F1FF" stopOpacity={1} />
                            <stop offset="0%" stopColor=" #F3F1FF" stopOpacity={0.5} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" interval={0} axisLine={false} tickLine={false} />
                    <YAxis hide />
                    <Tooltip />

                    <Area type="monotone" dataKey="This_Month" stroke="#18857A" strokeWidth={6} fillOpacity={1} fill="url(#colorUv)"
                        activeDot={{ strokeWidth: 2, r: 7 }}
                    />
                    <Area type="monotone" dataKey="Last_Month" stroke="#5F63F2" strokeWidth={6} fillOpacity={1} fill="url(#colorUv)"
                        activeDot={{ strokeWidth: 2, r: 7 }}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}


export default ServiceProviderChart;