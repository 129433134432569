import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import "../css/Login.css";
import { login, verifyCodeForget } from '../Path/Path';
import action from "../store/action";
import jwt_decode from 'jwt-decode';
import store from '../store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropDown from '../Componenets/DropDown';
import { useNavigate } from 'react-router-dom';
import DropDownLogin from '../Componenets/DropDownLogin';
import Loader from '../Componenets/Loader';
import { withRouter } from "../Componenets/withRouter";

class Forgot extends Component {


    state = {
        email: "",
        type: "admin",
        loading: "",
    }
    loginHandler = async () => {
        this.setState({ loading: true })
        if (this.state.email === "") {
            toast.warn("Enter Email")

        }

        else {

            let params = {
                email: this.state.email,
                type: this.state.type
            }
            await verifyCodeForget(params)
                .then((res) => {
                    if (res?.success) {
                        this.props.history("/resetPass", { state: { code: res?.code, email: this.state.email, type: this.state.type } })
                    } else {
                        toast.warn(res?.message)
                    }
                    console.log(res, "ressssssssssssssss")

                }).catch((err) => {
                    toast.warn(err.message)

                })

        }
        // this.props.history("/")
        this.setState({ loading: false })
    }

    render() {
        return (
            <div className='main-div-sign-in'>
                <ToastContainer />
                <Loader loading={this.state.loading} />
                <div className='sign-in-card'>
                    <div className='logo'>
                        <div className='harmoso-logo1'></div>
                    </div>
                    <div className='login-title1'>
                        <p className='headFor'>Forgot Password</p>
                        <p className='forText'>Enter the email address and type you used when you  joined and we will send you instructions to reset your password</p>
                    </div>
                    <div className='sign-in-flex1'>
                        <div className='sign-in-form'>
                            <div className='sign-in-email'>
                                <label htmlFor="email" className='label'>Email</label>
                                <input type="email" id='email' name='email' value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                            </div>
                            <div className='ty' style={{ marginTop: "2rem" }}>
                                <label htmlFor="type" className='label'>Select Type</label>
                                <DropDownLogin
                                    options={[
                                        { label: 'Admin', value: "admin" },
                                        { label: 'Financial Manager', value: "financial manager" },
                                        { label: 'Insepection Manager', value: "insepection manager" },
                                    ]}

                                    onChange={(e) => this.setState({ type: e.target.value })}
                                    value={this.state.type}
                                />
                            </div>
                        </div>
                        {/* <div className='rem-for'>
                            <div className='remember'>
                                <input type="checkbox" id='checkbox' />
                                <span className='keep-text' htmlFor="checkbox">Keep me logged in</span>
                            </div>
                            <p className='forget-para'  style={{ cursor: "pointer" }}>Forget Password?</p>
                        </div> */}
                        <div className='sign-in-btn1'>
                            <button className='sign-btn' onClick={() => this.loginHandler()} >Send reset instructions</button>
                        </div>
                        <div className='be-af-line'>
                            <p className='or'>or</p>
                        </div>
                        <div className='not-remember'> 
                            <p className='sign-up-link' onClick={()=> this.props.history("/")}>Return sign in</p>
                </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(Forgot) ;
