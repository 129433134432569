import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";



const SaloonRatingChart = ({saloonData}) => {
    console.log('saloonData: ', saloonData);


    const[data,setData] = useState([]);
    const create = "2022-10-05T17:27:09.751Z";
    const DATE_FORMAT = "Do";

    useEffect(()=>{
        getData()
    },[saloonData])

    const getData = async () =>{
        const newData = Array(30).fill(moment().format(DATE_FORMAT)).reduce((acc,item,i)=>{
            const name = moment().subtract(i,"days").format(DATE_FORMAT)
            const thisMonthLength = saloonData?.currentMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT)).length;
            const lastMonthLength = saloonData?.previousMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT)).length;
            const totalStarThisMonth = saloonData?.currentMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc,elem)=> acc + elem?.star, 0 );
            const totalStarLastMonth = saloonData?.previousMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc,elem)=> acc + elem?.star, 0 );
            const thisMonthRating = totalStarThisMonth / thisMonthLength; 
            const lastMonthRating = totalStarLastMonth / lastMonthLength 
            console.log('lastMonthRating: ', lastMonthRating);

            acc.push({
                name:name,
                This_Month:thisMonthRating,
                Last_Month:lastMonthRating,
            }) 

            return acc;
        },[])

        setData(newData.reverse());
    }


    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data}
                margin={{
                    top: 10,
                    right: 10,
                    left: -10,
                    bottom: 20
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" axisLine={false} tickLine={false}  interval={0} angle={-45} dy={10} stroke= '#4f4f4f' />
                <YAxis hide />
                <Tooltip />
                <Area
                    type="monotone"
                    dataKey="Last_Month"
                    stroke="#F9C74F"
                    strokeWidth={4}
                    fill="#F7EED6"
                />
                <Area
                    type="monotone"
                    dataKey="This_Month"
                    stroke="#2A5555"
                    strokeWidth={4}
                    fill="#2A555533"
                />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default SaloonRatingChart;