import React from 'react';
import { Routes, Route } from "react-router-dom";
import Sidebar from './Componenets/Sidebar';
import Dashboard from './Screens/Dashboard';
import Accounts from './Screens/Accounts';
import Email from './Screens/Email';
import Escrow from './Screens/Escrow';
import Earnings from './Screens/Earnings';
import AssignRoles from './Screens/AssignRoles';
import ServiceProviders from './Screens/ServiceProviders';
import Customers from './Screens/Customers';
import Login from "./Screens/Login";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { connect } from 'react-redux';
import Chat from "./Screens/chat"
import Forgot from './Screens/Forgot';
import PasswordReset from './Screens/PasswordReset';
import PasswordNew from './Screens/PasswordNew';


const App = (props) => {
  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div style={{ display: "flex" }}>
          {
            props?.logged ?
              <div style={{ width: "20%", minHeight: "100vh" }}>
                <Sidebar />
              </div>
              : null
          }
          <div style={{ width: "100%" }}>
            <Routes>
              {
                !props.user ?
                <React.Fragment>
                  <Route path="/" element={<Login />} />
                  <Route path="/forgetPassword" element={<Forgot />} />
                  <Route path="/resetPass" element={<PasswordReset />} />
                  <Route path="/newPass" element={<PasswordNew />} />
                </React.Fragment>
                  : <React.Fragment>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/accounts" element={<Accounts />} />
                    <Route path="/chat" element={<Chat />} />
                    <Route path="/escrow" element={<Escrow />} />
                    <Route path="/earnings" element={<Earnings />} />
                    <Route path="/assignRoles" element={<AssignRoles />} />
                    <Route path="/serviceProviders" element={<ServiceProviders />} />
                    <Route path="/customers" element={<Customers />} />

                  </React.Fragment>
              }
            </Routes>
          </div>
        </div>
      </LocalizationProvider>
    </React.Fragment>
  )
}

const mapState = (state) => {
  return {
    user: state.user,
    logged: state.logged
  }
}


export default connect(mapState, null)(App);
