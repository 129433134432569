import React, { useState } from 'react';
import { connect } from 'react-redux';
import "../css/Login.css";
import { login } from '../Path/Path';
import action from "../store/action";
import jwt_decode from 'jwt-decode';
import store from '../store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropDown from '../Componenets/DropDown';
import { useNavigate } from 'react-router-dom';
import DropDownLogin from '../Componenets/DropDownLogin';
import Loader from '../Componenets/Loader';

const Login = (props) => {


    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [type,setType] = useState("admin")
    const [loading,setLoading] = useState(false);

    const navigate = useNavigate();


    const signInHandler = async () => {
        const body = {
            "userName": "",
            "email": email,
            "password": password,
            "phone": "",
            "type": type,
            "image": "",
            "isActive": true
        }
        if (email === "") {
            toast.warn("Enter Email")
        }
        else if (password === "") {
            toast.warn("Enter password")
        }else if (type === ""){
            toast.warn("Enter type")
        }

        else {
            // await this.setState({ loading: true })
            console.log(body);
            setLoading(true)
            await login(body)
                .then((res) => {
                    console.log(res, "ressssssssssssssss")
                    if (res?.success) {

                        let info = jwt_decode(res?.token)
                        console.log('info: ', info);

                        store.dispatch(action.setUsers(info?.payload))
                        setLoading(false)
                        toast.success("Login Successfully")




                    }
                    else {
                        setLoading(false)
                        toast.warn("Please login as admin , financial manager or insepection manager!")

                    }

                }).catch((err) => {
                    console.log('err: ', err.message);
                    setLoading(false)
                    toast.warn("Something went wrong",err.message)
                })
        }

        // this.props.history("/")
    }

    console.log(props.user);
    return (
        <div className='main-div-sign-in'>
            <ToastContainer />
            <Loader loading={loading}/>
            <div className='sign-in-card'>
                <div className='logo'>
                    <div className='harmoso-logo'></div>
                </div>
                <div className='login-title'>
                    <p>Sign in to your account</p>
                </div>
                <div className='sign-in-flex'>
                    <div className='sign-in-form'>
                        <div className='sign-in-email'>
                            <label htmlFor="email" className='label'>Email</label>
                            <input type="email" id='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="sign-in-password">
                            <label htmlFor="password" className='label'>Password</label>
                            <input type="password" id='password' name='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <div className='ty' style={{ marginTop: "2rem" }}>
                            <label htmlFor="type" className='label'>Select Type</label>
                            <DropDownLogin
                                options={[
                                    { label: 'Admin', value: "admin" },
                                    { label: 'Financial Manager', value: "financial manager" },
                                    { label: 'Insepection Manager', value: "insepection manager" },
                                ]}
                                onChange={(e) => setType(e.target.value)}
                                value={type}
                            />
                        </div>
                    </div>
                    <div className='rem-for'>
                        <div className='remember'>
                            <input type="checkbox" id='checkbox' />
                            <span className='keep-text' htmlFor="checkbox">Keep me logged in</span>
                        </div>
                        <p className='forget-para' onClick={() => navigate("/forgetPassword")} style={{cursor:"pointer"}}>Forget Password?</p>
                    </div>
                    <div className='sign-in-btn' onClick={() => signInHandler()}>
                        <button className='sign-btn'  >Sign In</button>
                    </div>
                    {/* <div className='be-af-line'>
                        <p className='or'>or</p>
                    </div> */}
                    {/* <div className='not-remember'> */}
                    {/* <p className='rem-text'>{toggle ? "Already a member?" : "Not a member yet?"}</p> */}
                    {/* <p className='sign-up-link'>Sign In</p>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user
    }
}
const mapDispatchtoProps = (dispatch) => {
    return {
        _setUsers: payload => dispatch(action.setUsers(payload))

    }
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);
