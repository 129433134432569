import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";



const AreaGraph = ({myData}) => {

    const [data,setData] = useState([]);
    const MONTH_FORMAT = "MMM";

    useEffect(()=>{
        getData()
    },[myData])

    const getData = async () =>{
        const newData = Array(12).fill(moment().format(MONTH_FORMAT)).reduce((acc,item,i)=>{
            const name = moment().subtract(i,"month").format(MONTH_FORMAT);
            acc.push({
                name:name,
                Sales: myData?.currentYear?.filter((i) => moment(i.createdAt).format(MONTH_FORMAT) === name).length
            })
            return acc;
        },[])
        setData(newData.reverse())
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data}
                margin={{
                    top: 10,
                    right: 20,
                    left: 20,
                    bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="7 3" vertical={false} />
                <XAxis
                    dataKey="name"
                    tickLine={false}
                    dy={6}
                    // tick={{fontSize :12}}
                    interval={0}
                />
                <YAxis
                    hide
                />
                <Tooltip />
                <Area type="monotone" dataKey="Sales" stroke="#F3634C"  fill="#DDE5EA" />
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default AreaGraph;
