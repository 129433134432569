import React from 'react';

const Card = ({ head, num, span, source }) => {
    return (
        <div className='card'>
            <div className='card-flex'>
                <div className='card-text'>
                    <p className='profit-para'>{head}</p>
                    <p className='profit-num'>{num}</p>
                </div>
                <div className='card-icon'>
                    <img src={source} alt="" />
                </div>
            </div>
        </div>
    )
}

{/* <span style={{color:span.includes("+")?"#82D616":"#EA0606"}}>{span}</span> */}

export default Card;
