import axios from "axios";

export const BASE_URL = "https://backend.hermoso.app/";
// export const BASE_URL = "https://hermoso-server.herokuapp.com/";
// export const BASE_URL = "http://192.168.1.5:5000/";

export const PATHS = {
    GET_RECIPIENTS: BASE_URL + "channel/chat/recipients/",
    ADD_RECIPIENTS: BASE_URL + "channel/chat/recipients/add",
    READ_MESSAGES: BASE_URL + "channel/read/",
}


const login = async (payload) => {
    const { data } = await axios.post(`${BASE_URL}user/login`, payload);
    return data;
}

const signUp = async (payload) => {
    const { data } = await axios.post(`${BASE_URL}user/signup`, payload);
    return data;
}

const getAllUsers = async () => {
    const { data } = await axios.get(`${BASE_URL}user/get/all`)
    return data;
}

const deleteUser = async (id) => {
    const { data } = await axios.delete(`${BASE_URL}user/delete/${id}`)
}

const editUser = async (payload, id) => {
    const { data } = await axios.patch(`${BASE_URL}user/edit/${id}`, payload)
    return data;
}

const getAllStore = async () => {
    const { data } = await axios.get(`${BASE_URL}store/get/all`)
    return data;
}

const getBookingRunWait = async (id) => {
    const { data } = await axios.get(`${BASE_URL}booking/by/salon/${id}`)
    return data;
}

const getBookingData = async (id) => {
    const { data } = await axios.get(`${BASE_URL}booking/get/by/time/${id}`)
    return data;
}

const saloonRating = async (id) => {
    const { data } = await axios.get(`${BASE_URL}rating/get/by/time/${id}`)
    return data;
}

const editBooking = async (payload, id) => {
    const { data } = await axios.put(`${BASE_URL}booking/edit/${id}`, payload)
    return data;
}

const editTranaction = async (payload, id) => {
    const { data } = await axios.put(`${BASE_URL}transaction/edit/${id}`, payload)
    return data;
}

const getListing = async (id) => {
    const { data } = await axios.get(`${BASE_URL}service/by/store/for/dashboard/${id}`)
    return data;
}

const getBookingCustomer = async (id) => {
    const { data } = await axios.get(`${BASE_URL}booking/get/by/time/for/customer/${id}`)
    return data;
}

const userBookingCustomer = async (id) => {
    const { data } = await axios.get(`${BASE_URL}booking/by/user/${id}`)
    return data;
}

const totalSpent = async (id) => {
    const { data } = await axios.get(`${BASE_URL}booking/get/payments/by/user/${id}`)
    return data;
}

const getCustomersPayment = async () => {
    const { data } = await axios.get(`${BASE_URL}booking/get/payments/for/all/user`);
    return data;
}

const getAllSaloonSales = async () => {
    const { data } = await axios.get(`${BASE_URL}booking/get/payments/for/all/salon`)
    return data;
}

const salesOverview = async () => {
    const { data } = await axios.get(`${BASE_URL}booking/get/sales/by/year`)
    return data;
}

const getUserWeekly = async () => {
    const { data } = await axios.get(`${BASE_URL}user/get/by/weekly`)
    return data;
}

const addService = async (payload) => {
    const { data } = await axios.post(`${BASE_URL}service/add`, payload)
    return data;
}

const getDashboardData = async () => {
    const { data } = await axios.get(`${BASE_URL}booking/get/dashboard/data`)
    return data;
}

const getTransaction = async () => {
    const { data } = await axios.get(`${BASE_URL}transaction/get`)
    return data;
}


const getEscrowTop = async () => {
    const { data } = await axios.get(`${BASE_URL}store/get/escrow`)
    return data;
}

const moneyEarned = async () => {
    const { data } = await axios.get(`${BASE_URL}booking/get/earned/money`)
    return data;
}

const ourProfit = async () => {
    const { data } = await axios.get(`${BASE_URL}transaction/get/our/profit`)
    return data;
}

const getCategory = async () => {
    const { data } = await axios.get(`${BASE_URL}category/get`)
    return data;
}

const verifyCodeForget = async (params) => {
    const { data } = await axios.get(`${BASE_URL}user/send-reset-pass/${params.email}/${params.type}`)
    return data;
}
const resetPassword = async (email, body) => {
    const { data } = await axios.put(`${BASE_URL}user/reset/password/${email}`, body)
    return data;
}



export {
    login,
    getAllUsers,
    deleteUser,
    editUser,
    getAllStore,
    getBookingData,
    getBookingRunWait,
    saloonRating,
    editBooking,
    getListing,
    getBookingCustomer,
    userBookingCustomer,
    totalSpent,
    getCustomersPayment,
    getAllSaloonSales,
    salesOverview,
    getUserWeekly,
    addService,
    getDashboardData,
    signUp,
    getEscrowTop,
    moneyEarned,
    getTransaction,
    ourProfit,
    getCategory,
    verifyCodeForget,
    resetPassword,
    editTranaction
}