import React, { useEffect, useState } from 'react';
import Header from '../Componenets/Header';
import "../css/ServiceProvider.css";
import profile from "../assets/profile.jpg";
import Profile from '../Componenets/Profile';
import TotalBokingChart from '../Graphs/TotalBokingChart';
import RunWaitChart from '../Graphs/RunWaitChart';
import DropDown from '../Componenets/DropDown';
import data, { data2 } from './StaticData';
import SaloonRatingChart from '../Graphs/SaloonRatingChart';
import TotalSpentChart from '../Graphs/TotalSpentChart';
import "../css/Customer.css";
import swal from "sweetalert";
import CustomerTotalBooking from '../Graphs/CustomerTotalBooking';
import { getAllUsers, getBookingCustomer, totalSpent, userBookingCustomer } from '../Path/Path';
import Loader from '../Componenets/Loader';
import { toast, ToastContainer } from 'react-toastify';
import CustomerProfile from '../Componenets/CustomerProfile';
import CustomerRunWait from '../Graphs/CustomerRunWait';
import moment from 'moment';

const Customers = () => {

  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [bookingDataCustomer, setBookingDataCustomer] = useState([]);
  const [spent, setSpent] = useState([]);
  const [profileId,setProfileId] = useState();
  const [searchVal,setSearchVal] = useState("");



  useEffect(() => {
    getProfiles()
  }, [])

  const getProfiles = async () => {
    setLoading(true)
    await getAllUsers().then((res) => {
      console.log('res: ', res);
      if (res.length > 0) {
        res = res.filter((i) => i.type === "customer" && i.isActive === true);
        setProfile(res)
        setLoading(false)

      } else {
        toast.warn("No data found!");
        setLoading(false)
      }
    }).catch((err) => {
      toast.error("Something went wrong!");
      setLoading(false)
    })
  }


console.log("profileId",profileId);
  const profileData = async (item) =>{
    setProfileId(item._id)
    setLoading(true)
    await getBookingCustomer(item._id).then((res)=>{
      setBookingData(res)
      setLoading(false)
    }).catch((err)=>{
      toast.error("Something went wrong!")
    })

    await userBookingCustomer(item._id).then((res)=>{
      setBookingDataCustomer(res.docs)
      setLoading(false)
    }).catch((err)=>{
      toast.error("Something went wrong!")
    })

    await totalSpent(item._id).then((res)=>{
      setSpent(res)
      setLoading(false)
    }).catch((err)=>{
      toast.error("Something went wrong!")
    })
    setLoading(false)
  }

  console.log("bookingDataCustomer",bookingDataCustomer);

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <ToastContainer />
      <Header head={"Customers"} paraDash={"Get all information about customers."} search placeholder={"Search by customer name"} getSearchValue={(value) => setSearchVal(value) }  />
      <div className='main-div'>
        <div className='first-flex'>
          <div className='service-area1'>
            <p className='area-para'>Profile</p>
            <div className='line'></div>
            <div className='profiles-scrol'>
              {
                profile?.filter((val)=>{
                  if(searchVal == ""){
                    return val
                  }else if(val?.userName?.toUpperCase()?.includes(searchVal.toUpperCase())){
                    return val
                  }
                }).map((item) =>
                  <CustomerProfile
                    profileName={item.userName}
                    profileMail={item.email}
                    onClick={() => profileData(item)}
                  />
                )
              }
            </div>
          </div>
          <div className='registration-req4'>
            <div className='ris'>
              <div className='reqs-ta1'><p className='area-para'>Bookings</p></div>
              <div className='ris-table1'>
                <table className='ris-tables1'>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Saloon Name</th>
                      <th>Service Name</th>
                      <th>Price</th>
                      <th>Booking Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingDataCustomer?.reverse().map((elem, i) =>
                      <tr>
                        <td>{elem?.userID?.userName}</td>
                        <td>{elem?.salonID?.name}</td>
                        <td>{elem?.serviceID?.name}</td>
                        <td>{elem?.totalPrice}</td>
                        <td>{moment(elem?.createdAt).format("D-MMM-YYYY")}</td>
                        <td>
                          <p className={elem?.status === "completed" ? "select" : elem?.status === "pending"? "select1" : elem?.status === "cancel" ? "select2" : null}>{elem?.status === "completed"? "Completed" : elem?.status === "pending"? "Pending" : elem?.status === "cancel" ? "Cancel" : null}</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='total-booking-flex1'>
          <div className='total-flex'>
            <p className='area-para'>Total Booking</p>
            <div className='this-last-mont'>
              <p className='this'>This Month</p>
              <p className='last'>Last Month</p>
            </div>
          </div>
          <div className='total-chart'>
            <CustomerTotalBooking booking={bookingData} />
          </div>
        </div>
        <div className='total-booking-flex1'>
          <div className='total-flex'>
            <p className='area-para'>Running and Waiting Booking</p>
            <div className='this-last-mont'>
              <p className='run1'>Completed</p>
              <p className='wait1'>Waiting</p>
              <p className='cancel1'>Cancel</p>
            </div>
          </div>
          <div className='total-chart1'>
            <CustomerRunWait runWait={bookingDataCustomer} />
          </div>
        </div>
        <div className='total-spen-chart'>
          <div className='spent'>
            <div className='saloon-rating-flex'>
              <p className='area-para'>Total Spent</p>
              <div className='this-last-month'>
                <p className='thiss-month'>This Month</p>
                <p className='lasts-month'>Last Month</p>
              </div>
            </div>
          </div>
          <TotalSpentChart spentData={spent} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Customers;
