import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import "../css/Modal.css";
import DropDown from './DropDown';
import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";
import { getImageURL } from '../services/helper';
import { useEffect } from 'react';
import Loader from './Loader';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';


const ServiceAddModel = ({
    open,
    onClick,
    onUpdate,
    onClose,
    getName,
    getDescription,
    getPrice,
    getImageData,
    getStatus,
    clearImage,
    getLinks,
    name,
    description,
    price,
    statues,
    categoryData,
    profileId,
    categoriesData,
    getCate,
    catego

}) => {

    const [imgData, setImgData] = useState([]);
    console.log("categoriesData", categoriesData);
    const [imageAsUrl, setImageAsUrl] = useState("");
    const [uploadFiles, setUploadFiles] = useState([])
    const [links, setLinks] = useState([])
    const [loading, setLoading] = useState(false);


    const imageHandler = (e) => {

        const arr = Array.from(e.target.files)
        setImgData([])
        setUploadFiles(arr);


    }

    useEffect(() => {
        getImages()
    }, [uploadFiles])

    const getImages = () => {
        uploadFiles.forEach((item, index) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {

                    setImgData(s => [...s, reader.result])
                }
            }
            reader.readAsDataURL(item)

        })
    }

    // const dargging = (e) => {
    //     const forms = document.querySelector("form")
    //     e.preventDefault();
    //     forms.classList.add("dragover")
    // }

    // const dropImage = (e) => {
    //     const forms = document.querySelector("form")
    //     e.preventDefault();
    //     forms.classList.remove("dragover")
    //     const arr = Array.from(e.dataTransfer.files)
    //     setImgData(arr)
    //     setUploadFiles(arr);
    //     getImages()
    //     // getImageData(imgData)
    // }

    // const leaveDragging = (e) => {
    //     const forms = document.querySelector("form")
    //     e.preventDefault();
    //     forms.classList.remove("dragover")
    // }

    const updateHandler = async () => {
        if (name === "") {
            toast.warn("Please enter listing name.")
        } else if (description === "") {
            toast.warn("Please enter listing description.")
        } else if (!price) {
            toast.warn("Please enter listing price.")
        } else if (profileId === "") {
            toast.warn("Please select the saloon")
        } else if (imgData.length < 1) {
            toast.warn("Please select listing image.")
        } else if (catego === "") {
            toast.warn("Please select category.")
        } else {

            setLoading(true)
            const promises = uploadFiles.map(f => getImageURL(f, null,))
            const links = await Promise.all(promises)
            getLinks(links)
            await onUpdate(links)
            setImgData([])
            setLoading(false)
        }
    }

    const closeHandler = () => {
        setImgData([])
        onClose()
    }

    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <ToastContainer />
            <Loader loading={loading} />
            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", alignItems: "center" }}>
                <h2 className='h2-head' style={{ fontSize: "2.5rem" }}>Add Listing</h2>
                <div className='close-btn' style={{ margin: "-10px", fontSize: "3rem", cursor: "pointer" }} onClick={onClose}><TiDelete /></div>
            </div>
            <div className='line'></div>
            <DialogContent>
                <div className="modal-main" style={{ width: "500px" }} >
                    <div>
                        <label htmlFor='Name'>Name</label>
                        <input type="text" id='Name' value={name} placeholder='Name' onChange={(e) => getName(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor='Description'>Description</label>
                        <input type="text" id='Description' value={description} placeholder='Description' onChange={(e) => getDescription(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor='Price'>Price</label>
                        <input type="text" id='Price' value={price} placeholder='Price' onChange={(e) => getPrice(e.target.value)} />
                    </div>
                    <div>
                        <label htmlFor='Status'>Status</label>
                        <DropDown
                            options={[
                                { label: 'Active', value: Number(1) },
                                { label: 'Deactive', value: Number(0) },
                            ]}
                            onChange={(e) => getStatus(e.target.value)}
                            value={statues}
                        />
                    </div>
                    <div>
                        <label htmlFor='Status'>Category</label>
                        <DropDown
                            options={[{value:"", label:"Select Category"},...categoriesData.map(option => ({ label: option.categoryName, value: option._id }))]}
                            onChange={(e) => getCate(e.target.value)}
                            value={catego}
                        />
                    </div>

                    <div style={{ marginBottom: "2%" }}>
                        <label>Gallery Images</label>
                        <form action='/upload' method='post' className='' >
                            <span className='inner'>Upload Images <label className='selec' htmlFor="file">Browser</label></span>
                            <input name="file" type="file" id='file' className='file' multiple accept='image/*' onChange={imageHandler} />
                        </form>
                        <div className='cont'>
                            {/* {
                                imgData &&
                                imgData?.map((images, index) =>  */}
                            {
                                imgData.map((imgFile, i) =>
                                    <div className='image' key={i}>
                                        <img src={imgFile} alt="" />
                                        <span onClick={() => setImgData(imgData.filter((e, index) => index !== i))}>&times;</span>
                                    </div>
                                )
                            }
                            {/* )
                            } */}
                        </div>
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={closeHandler}>Cancel</button>
                </div>
                <div className='save-changes'>
                    <button onClick={updateHandler}>Save Changes</button>
                </div>
            </div>
        </Dialog>
    )
}

export default ServiceAddModel;