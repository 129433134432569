import React, { useState } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";


const PieDonutChart = ({myPieData}) => {
    
    const res = myPieData.filter((i) => i.createdAt).reduce((acc,item) => acc + item.total ,0)
    
    const data = [
        { name: "Total Amount", value: res },
    ];
    const COLORS = ["#326565"];

    return (
        <ResponsiveContainer width="100%" height="100%" >
            <PieChart
            >
                <Tooltip />
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={120} innerRadius={50}
                    fill="#8884d8"
                    paddingAngle={0}
                    dataKey="value"
                    startAngle={180}
                    endAngle={-180}

                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}


export default PieDonutChart;