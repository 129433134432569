import React, { useEffect, useState } from 'react';
import moment from "moment";
import {
    ComposedChart,
    Line,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";

const TotalBokingChart = ({myData}) => {

    const[data,setData] = useState([]);


    useEffect(()=>{
        getData()
    },[myData])
    const DATE_FORMAT = "Do";


    const getData = async () =>{
        const newData = Array(30).fill(moment().format(DATE_FORMAT)).reduce((acc,item,i)=>{
            const name = moment().subtract(i, "days").format(DATE_FORMAT)
            acc.push({
                name:name,
                This_Month: myData?.currentMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT) === name).length,
                Last_Month: myData?.previousMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT) === name).length
            })
            return acc;
        },[])
        setData(newData.reverse())
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={data}
                margin={{
                    top: 20, right: 30, bottom: 20, left: 30,
                }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="10%" stopColor="rgba(65, 105, 225, 0.12)" stopOpacity={1} />
                        <stop offset="100%" stopColor="rgba(65, 105, 225, 0.01) " stopOpacity={1} />
                    </linearGradient>
                </defs>
                <CartesianGrid stroke="#f5f5f5" vertical={false} />
                <XAxis dataKey="name" angle={-45} strokeWidth={1} dy={10} interval={0} />
                <Tooltip />
                <Area type="monotone" dataKey="Last_Month" stroke="#2A5555" strokeWidth={4} fillOpacity={1} fill="url(#colorUv)" />
                <Line type="monotone" dataKey="This_Month" stroke="#F4517F" strokeWidth={2} strokeDasharray="5 5" dot={false} />
            </ComposedChart>
        </ResponsiveContainer>
    );
}


export default TotalBokingChart;