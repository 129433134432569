import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import "../css/Login.css";
import { login, resetPassword, verifyCodeForget } from '../Path/Path';
import action from "../store/action";
import jwt_decode from 'jwt-decode';
import store from '../store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropDown from '../Componenets/DropDown';
import { useNavigate } from 'react-router-dom';
import DropDownLogin from '../Componenets/DropDownLogin';
import Loader from '../Componenets/Loader';
import { withRouter } from "../Componenets/withRouter";

class PasswordNew extends Component {


    state = {
        email: this.props.location?.state?.email,
        password: "",
        confirmPassword: "",
        loading: "",
    }
    changePasswordHandler = async () => {
        this.setState({ loading: true })
        if (this.state.password === "") {
            toast.warn("Enter New Password")

        }
        else if (this.state.confirmPassword === '') {
            toast.warn("Enter Confirm Password")
        }

        else {
            if (this.state.confirmPassword === this.state.password) {
                let params = {
                    password: this.state.password,
                }
                await resetPassword(this.state.email, params)
                    .then((res) => {
                        if (res?.success) {
                            this.props.history("/")
                        } else {
                            toast.warn(res?.message)
                        }
                        console.log(res, "ressssssssssssssss")

                    }).catch((err) => {
                        toast.warn(err.message)

                    })
            }
            else {
                toast.warn('Your password is not matched')
            }


        }
        // this.props.history("/")
        this.setState({ loading: false })
    }

    render() {
        return (
            <div className='main-div-sign-in'>
                <ToastContainer />
                <Loader loading={this.state.loading} />
                <div className='sign-in-card'>
                    <div className='logo'>
                        <div className='harmoso-logo1'></div>
                    </div>
                    <div className='login-title1'>
                        <p className='headFor'>Reset Password</p>
                    </div>
                    <div className='sign-in-flex1'>
                        <div className='sign-in-form'>
                            <div className='sign-in-email'>
                                <label htmlFor="email" className='label'>New Password</label>
                                <input type="email" id='email' name='email' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                            </div>
    
                            <div className='sign-in-email'>
                                <label htmlFor="email" className='label'>Confirm Password</label>
                                <input type="email" id='email' name='email' value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                            </div>
    
                        </div>
                       
                        <div className='sign-in-btn1'>
                            <button className='sign-btn' onClick={() => this.changePasswordHandler()}  >Reset</button>
                        </div>
                 
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(PasswordNew) ;
