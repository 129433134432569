import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import "../css/Login.css";
import { login, verifyCodeForget } from '../Path/Path';
import action from "../store/action";
import jwt_decode from 'jwt-decode';
import store from '../store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DropDown from '../Componenets/DropDown';
import { useNavigate } from 'react-router-dom';
import DropDownLogin from '../Componenets/DropDownLogin';
import Loader from '../Componenets/Loader';
import { withRouter } from "../Componenets/withRouter";
import PinInput from 'react-pin-input';

class PasswordReset extends Component {


    state = {
        email: this.props.location?.state?.email,
        password: "",
        loading: "",
        type: this.props.location.state.type,
        verifyCode: this.props.location?.state?.code,
        code: "",
    }
    handler = () => {
        if (this.state.code === this.state.verifyCode) {
            this.props.history("/newPass", { state: { email: this.state.email } })
        }
        else {
            toast.warn('Your code is not valid')
        }
    }
    resendCode = async () => {
        this.setState({ loading: true })
        if (this.state.email === "") {
            toast.warn("Enter Email")

        }

        else {

            let params = {
                email: this.state.email,
                type: this.state.type
            }
            await verifyCodeForget(params)
                .then((res) => {
                    if (res?.success) {
                        this.setState({ verifyCode: res?.code, code: '' })
                    } else {
                        toast.warn(res?.message)
                    }
                    console.log(res, "ressssssssssssssss")

                }).catch((err) => {
                    toast.warn(err.message)

                })

        }
        // this.props.history("/")
        this.setState({ loading: false })
    }

    render() {
        return (
            <div className='main-div-sign-in'>
                <ToastContainer />
                <Loader loading={this.state.loading} />
                <div className='sign-in-card'>
                    <div className='logo'>
                        <div className='harmoso-logo1'></div>
                    </div>
                    <div className='login-title2'>
                        <p className='headFor'>OTP</p>
                    </div>
                    <div className='sign-in-flex1'>
                        <div className='sign-in-form'>
                            <PinInput

                                length={5}
                                initialValue=""

                                onChange={(value, index) => { this.setState({ code: value }) }}
                                type="text"
                                style={{ padding: '12px', justifyContent: "center" }}
                                inputStyle={{ borderColor: 'rgba(206, 204, 204, 0.61)' }}
                                inputFocusStyle={{ borderColor: 'rgba(206, 204, 204, 0.61)' }}
                                onComplete={(value, index) => { }}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            />
                        </div>
                        <div className='sign-in-btn1'>
                            <button className='sign-btn' onClick={() => this.handler()} >Verify Code</button>
                        </div>
                        <div className='be-af-line'>
                            <p className='or'>or</p>
                        </div>
                        <div className='not-remember'>
                            <p className='sign-up-link' onClick={() => this.resendCode()}>Resend code</p>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}

export default withRouter(PasswordReset);
