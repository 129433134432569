import React from 'react';
// import { AiOutlineMinusCircle, AiOutlinePlusCircle,AiOutlineExclamationCircle } from "react-icons/ai";


const TransactionCard = ({ percent, dates, proName }) => {
  return (
    <div>
      <div className='transaction-card'>
        <div className='item-icon'>
          {/* <div className='trans-card-icon'>{percent.includes("-")?<AiOutlineMinusCircle style={{color:"#EA0606"}}/> : percent.includes("+")? <AiOutlinePlusCircle style={{color:"#82D616"}}/> : <AiOutlineExclamationCircle style={{color:"#099EDA"}}/>}</div> */}
          <div className='item'>
            <p className='item-name'>{proName}</p>
            <p className='item-date'>{dates}</p>
          </div>
        </div>
        <p className='item-per' style={{ color: percent === 'Pending' ? 'red' : "#82D616" }}>{percent}</p>
      </div>
    </div>
  )
}

export default TransactionCard;
