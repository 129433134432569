import React from 'react';
import "../css/Sidebar.css";
import { AiFillHome, AiFillDollarCircle } from "react-icons/ai";
import { ImUser, ImUserPlus, ImUsers } from "react-icons/im";
import { IoIosMail } from "react-icons/io";
import { BsBank2 } from "react-icons/bs";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { RiLogoutBoxRFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import store from '../store';
import action from '../store/action';

const Sidebar = (props) => {

    const navigate = useNavigate();


    const path = window.location.pathname;

    const Logout = () =>{
        store.dispatch(action.logout())
        navigate("/")
    }

    return (
        <div className='main-div-sidebar'>
            <div className='logo-flex'>
                <div className='sidebar'>
                    <div className='logo-sidebar'><img  src={require("../assets/hermoso-logo.jpg")} alt=""  /></div>
                </div>
            </div>
            <div className='link-sidebar'>
                <div className={path === "/" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/")}>
                    <div className={path === "/" ? "side-bar-icon-sel" : 'side-bar-icon'}><AiFillHome /></div>
                    <p className={path === "/" ? "icon-para-sel" :'icon-para'}>Dashboard</p>
                </div>
                <div className={path === "/accounts" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/accounts")}>
                    <div className={path === "/accounts" ? "side-bar-icon-sel" : 'side-bar-icon'}><ImUser /></div>
                    <p className={path === "/accounts" ? "icon-para-sel" :'icon-para'}>Accounts</p>
                </div>
                <div className={path === "/chat" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/chat")}>
                    <div className={path === "/chat" ? "side-bar-icon-sel" : 'side-bar-icon'}><IoIosMail /></div>
                    <p className={path === "/chat" ? "icon-para-sel" :'icon-para'}>Chats</p>
                </div>
                <div className={path === "/escrow" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/escrow")}>
                    <div className={path === "/escrow" ? "side-bar-icon-sel" : 'side-bar-icon'}><BsBank2 /></div>
                    <p className={path === "/escrow" ? "icon-para-sel" :'icon-para'}>Escrow</p>
                </div>
                <div className={path === "/earnings" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/earnings")}>
                    <div className={path === "/earnings" ? "side-bar-icon-sel" : 'side-bar-icon'}><AiFillDollarCircle /></div>
                    <p className={path === "/earnings" ? "icon-para-sel" :'icon-para'}>Earnings</p>
                </div>
                <div className={path === "/assignRoles" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/assignRoles")}>
                    <div className={path === "/assignRoles" ? "side-bar-icon-sel" : 'side-bar-icon'}><ImUserPlus /></div>
                    <p className={path === "/assignRoles" ? "icon-para-sel" :'icon-para'}>Assign Role</p>
                </div>
                <div className={path === "/serviceProviders" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/serviceProviders")}>
                    <div className={path === "/serviceProviders" ? "side-bar-icon-sel" : 'side-bar-icon'}><MdOutlineMiscellaneousServices /></div>
                    <p className={path === "/serviceProviders" ? "icon-para-sel" :'icon-para'}>Service Providers</p>
                </div>
                <div className={path === "/customers" ? "text-icon-sel" : 'text-icon'} onClick={() => navigate("/customers")}>
                    <div className={path === "/customers" ? "side-bar-icon-sel" : 'side-bar-icon'}><ImUsers /></div>
                    <p className={path === "/customers" ? "icon-para-sel" :'icon-para'}>Customer</p>
                </div>
                <div className='text-icon'>
                    <div className='side-bar-icon'><RiLogoutBoxRFill /></div>
                    <p className='icon-para' onClick={() => Logout()}>Logout</p>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;
