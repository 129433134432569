import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Saloon = ({ imgSrc, saPara, salPara, item }) => {

    const history = useNavigate()


    const goToChat = () => {
        history("/chat", { state: { selectedUser: item.ownerID } })
    }

    return (
        <div>
            <div className='cont-con'>
                <div className='im-del'>
                    <img src={imgSrc} alt="" />
                    <div className='saloon-para'>
                        <p className='sa-para'>{saPara}</p>
                        <p className='sal-para'>{salPara}</p>
                    </div>
                </div>
                <div className='con-cli' onClick={goToChat} >
                    <button type='text'>Contact Client</button>
                </div>
            </div>
        </div>
    )
}

export default Saloon;
