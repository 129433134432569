import React from 'react';
import "../css/Header.css";
import { HiOutlineSearch } from "react-icons/hi";
import action from "../store/action";
import jwt_decode from 'jwt-decode';
import store from '../store';
import { connect } from 'react-redux';
const Header = ({head,paraDash,user,getSearchValue,placeholder,search}) => {

    return (
        <div className='main-div-header'>
            <div className='header-flex'>
                <div className='header-top'>
                    <h2 className='heading'>{head}</h2>
                    <p className='header-para'>{paraDash}</p>
                </div>
                {
                    search &&
                <div className='search-header'>
                    <div className='search-icon' style={{cursor:"pointer"}} ><HiOutlineSearch /></div>
                    <input type="text" placeholder={placeholder} onChange={(e) => getSearchValue(e.target.value)}  />
                </div>
                }
                <div className='profile'>
                    <div className='profile-img'>
                        <img src={require("../assets/pic.jpg")} alt="" />
                    </div>
                    <div className='profile-text'>
                        <p className='person-name'>{user.userName}</p>
                        <p className='person-admin'>{user?.type?.charAt(0)?.toUpperCase() + user?.type?.slice(1)} account</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStatetoProps = (state) => {
    return {
        user: state.user
    }
}
export default connect(mapStatetoProps, null)(Header);

