import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import "../css/Modal.css";
import drop from "../assets/drop.png"
import { TiDelete } from "react-icons/ti";

const ViewModal = ({onClose,Name,AccountType,Status,open,email,srcImg}) => {
    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", alignItems: "center" }}>
                {
                    window.location.pathname === "/accounts" ?
                        <h2 className='h2-head' style={{ fontSize: "2.5rem" }}>View Account Status</h2>
                        :
                        window.location.pathname === "/assignRoles" ?
                            <h2 className='h2-head' style={{ fontSize: "2.5rem" }}>View Role Status</h2>
                            : null
                }
                <div className='close-btn' style={{ margin: "-10px", fontSize: "3rem", cursor: "pointer" }} onClick={onClose}><TiDelete /></div>
            </div>
            <div className='line'></div>
            <DialogContent>
                <div className='modal-main' style={{ width: "500px",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"2rem",marginBottom:"2rem" }}>
                    <img src={srcImg} alt="" style={{width:"60px",heigth:"60px",marginBottom:"2rem"}}/>
                    <p style={{fontSize:"1.6rem",marginBottom:"1.5rem",fontWeight:"700"}}>Name: <span style={{fontWeight:"400"}}>{Name}</span></p>
                    {
                        email?
                        <p style={{fontSize:"1.6rem",marginBottom:"1.5rem",fontWeight:"700"}}>Email: <span style={{fontWeight:"400"}}>{AccountType}</span></p>
                        :
                        <p style={{fontSize:"1.6rem",marginBottom:"1.5rem",fontWeight:"700"}}>Account Type: <span style={{fontWeight:"400"}}>{AccountType}</span></p>
                    }
                    <p style={{fontSize:"1.6rem",marginBottom:"1.5rem",fontWeight:"700"}}>Status: <span style={{fontWeight:"400"}}>{Status}</span></p>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ViewModal
