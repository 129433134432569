import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

// const data = [
//     {
//         name: "Jun",
//         uv: 40,
//         pv: 240,
//         amt: 2400
//     },
//     {
//         name: "Feb",
//         uv: 300,
//         pv: 138,
//         amt: 2210
//     },
//     {
//         name: "Mar",
//         uv: 200,
//         pv: 80,
//         amt: 2290
//     },
//     {
//         name: "Apr",
//         uv: 270,
//         pv: 398,
//         amt: 2000
//     },
//     {
//         name: "May",
//         uv: 180,
//         pv: 100,
//         amt: 2181
//     },
//     {
//         name: "Jun",
//         uv: 230,
//         pv: 300,
//         amt: 2500
//     },
//     {
//         name: "Jul",
//         uv: 230,
//         pv: 300,
//         amt: 2500
//     },
// ];



const RunWaitChart = ({runData}) => {

    const [data,setData] = useState([]);
    const MONTH_FORMAT = "MMM";

    useEffect(()=>{
        getData()
    },[runData])

    const getData = async () =>{
        const newData = Array(12).fill(moment().format(MONTH_FORMAT)).reduce((acc,item,i)=>{
            const name = moment().subtract(i,"month").format(MONTH_FORMAT);
            acc.push({
                name:name,
                Waiting: runData?.filter(i => moment(i.createdAt).format(MONTH_FORMAT) === name).filter(i => i.status === "pending").length
            })
            return acc;

        },[])

        setData(newData.reverse())
    }
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={data}
                margin={{
                    top: 10, right: 20, bottom: 10, left: 20,
                }}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="30%" stopColor="#0CA592" stopOpacity={1} />
                        <stop offset="0%" stopColor="#0CA592" stopOpacity={1} />
                    </linearGradient>
                </defs>
                <CartesianGrid stroke="#B9B9BD" strokeDasharray="2 7"  />
                <YAxis hide/>
                <XAxis  
                dataKey="name" 
                axisLine={false}
                 interval={0}
                textAnchor="center"
                tick={{fontSize:12}}
                />
                <Tooltip />
                <Line type="monotone" dataKey="Waiting" stroke="#F4517F" strokeWidth={6}  dot={false} />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export default RunWaitChart;