import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import { getCustomersPayment } from "../Path/Path";

// const data = [
//     {
//         name: "Jan",
//         uv: 4000,
//         pv: 2400,
//         amt: 2400
//     },
//     {
//         name: "Feb",
//         uv: 3000,
//         pv: 1398,
//         amt: 2210
//     },
//     {
//         name: "Mar",
//         uv: 2000,
//         pv: 9800,
//         amt: 2290
//     },
//     {
//         name: "Apr",
//         uv: 2780,
//         pv: 3908,
//         amt: 2000
//     },
//     {
//         name: "May",
//         uv: 1890,
//         pv: 4800,
//         amt: 2181
//     },
//     {
//         name: "Jun",
//         uv: 2390,
//         pv: 3800,
//         amt: 2500
//     },
//     {
//         name: "Jul",
//         uv: 3490,
//         pv: 4300,
//         amt: 21.00
//     },
//     {
//         name: "Aug",
//         uv: 3490,
//         pv: 4300,
//         amt: 21.0
//     },
//     {
//         name: "Sep",
//         uv: 3490,
//         pv: 4300,
//         amt: 2100
//     },
//     {
//         name: "Oct",
//         uv: 3490,
//         pv: 4300,
//         amt: 2100
//     },
//     {
//         name: "Nov",
//         uv: 3490,
//         pv: 4300,
//         amt: 2100
//     },
//     {
//         name: "Dec",
//         uv: 3490,
//         pv: 4300,
//         amt: 2100
//     }
// ];

const TotalPaymentChart = ({myPayments}) => {


    const [data, setData] = useState([]);
    const DATE_FORMAT = "Do"

    useEffect(() => {
        getData()
    }, [myPayments])

    const getData = async () => {
       

        const newData = Array(30).fill(moment().format(DATE_FORMAT)).reduce((acc, item, i) => {
            const name = moment().subtract(i, "days").format(DATE_FORMAT);
            acc.push({
                name: name,
                This_Month: myPayments?.currentMonth?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc, item) => acc + item.totalPrice, 0),
                Last_Month: myPayments?.previousMonth?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc, item) => acc + item.totalPrice, 0)
            })
            return acc;
        }, [])

        setData(newData.reverse());
    }





    return (
        <div className="payment-ration-chart">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 20,
                        bottom: 50
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" axisLine={false} tickLine={false} interval={0} stroke='#4f4f4f' />
                    <YAxis hide />
                    <Tooltip />
                    <Area
                        type="monotone"
                        dataKey="Last_Month"
                        stroke="#F9C74F"
                        strokeWidth={4}
                        fill="#F7EED6"
                    />
                    <Area
                        type="monotone"
                        dataKey="This_Month"
                        stroke="#2A5555"
                        strokeWidth={4}
                        fill="#2A555533"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

export default TotalPaymentChart;