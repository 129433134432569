import React, { useEffect, useState } from 'react';
import "../css/Account.css";
import "../css/Table.css";
import Header from '../Componenets/Header';
import { TiArrowForward, TiDelete } from "react-icons/ti";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import DropDown from '../Componenets/DropDown';
import data from './StaticData';
import Modal from '../Componenets/Modal';
import swal from "sweetalert";
import { deleteUser, editUser, getAllUsers, signUp } from '../Path/Path';
import Loader from '../Componenets/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../Componenets/Pagination';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment';
import ViewModal from '../Componenets/ViewModal';
import DatePicker from "react-datepicker";
import AddAccountsModal from '../Componenets/AddAccountsModal';

const AssignRoles = () => {

  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [roles, setRoles] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [filterToggle, setFilterToggle] = useState(false);
  const [loading, setLoading] = useState(false)
  const [viewName, setViewName] = useState("")
  const [viewType, setViewType] = useState("")
  const [viewStatus, setViewStatus] = useState("")
  const [view, setView] = useState(false)
  const [searchVal, setSearchVal] = useState("");
  const [srcImage, setSrcImage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [visi, setVisi] = useState(false);
  const [personUserName, setPersonUserName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [personPassword, setPersonPassword] = useState("")
  const [personType, setPersonType] = useState("admin");

  const elem = 12;

  useEffect(() => {
    getData()
  }, [])


  const delDate = async () => {
    setStartDate("")
    setLoading(true)
    await getData()
    setLoading(false)
  }

  const getData = async () => {
    setLoading(true)
    await getAllUsers().then((res) => {
      console.log("res", res);
      if (res.length > 0) {

        res = res.filter((i) => i.type === "admin" || i.type === "financial manager" || i.type === "inspection manager")
        setAllUsers(res)
        setLoading(false)
      } else {
        toast.warn("No data found!")
        setLoading(false)
      }
    }).catch((err) => {
      toast.error("Something went wrong!")
      setLoading(false)
    })
  }

  const filterData = async (e) => {
    setStartDate(e)
    setLoading(true)
    await getAllUsers().then((res) => {
      console.log("res", res);
      if (res.length > 0) {

        res = res.filter((i) => i.type === "admin" || i.type === "financial manager" || i.type === "inspection manager")
        const result = res.filter((i) => moment(i.createdAt).format("D-MMM-YYYY") === moment(e).format("D-MMM-YYYY"))
        setAllUsers(result)
        setLoading(false)
      } else {
        toast.warn("No data found!")
        setLoading(false)
      }
    }).catch((err) => {
      toast.error("Something went wrong!")
      setLoading(false)
    })
  }



  const modalHandler = (elem) => {
    setId(elem._id)
    setUserName(elem?.userName)
    setRoles(elem?.type)
    setEmail(elem?.email)
    setSrcImage(elem?.image)
    setVisible(true)
  }

  const editHandler = async () => {
    if (!userName) {
      toast.warn('Enter username.')
    }
    else {
      let body = {
        userName: userName,
        email: email,
        type: roles
      }
      setLoading(true)
      await editUser(body, id).then(async (res) => {
        setUserName("")
        setEmail("")
        setRoles("")
        await getData()
        setLoading(false)
        setVisible(false)
        toast.success("User Edit Successfully")
      }).catch((error) => {
        toast.error("User cannot be edit.")
      })
    }
  }

  const deleteHandler = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure to delete this user?",
      icon: "warning",
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await deleteUser(id);
          await getData()
          swal("Deleted!", "User deleted successful ", "success");
        }
      }).catch((err) => {
        toast.error("User cannot be deleted.")
      })
  }

  const totalPage = parseInt((allUsers.length / 12) + 1)

  const viewModel = (elem) => {
    setView(true)
    setViewName(elem.userName);
    setViewType(elem.email)
    setViewStatus(elem.type)
  }


  const addAccountHandler = () => {
    setVisi(true);
  }

  const onAddAccounts = async () => {
    let body = {
      userName: personUserName,
      email: personEmail,
      password: personPassword,
      type: personType,
      isActive: true,
    }
    if (personUserName === "") {
      toast.warn("Please enter username.")
    } else if (personEmail === "") {
      toast.warn("Please enter email.")
    } else if (personPassword === "") {
      toast.warn("Please enter password.")
    } else {

      setLoading(true)
      await signUp(body).then(() => {
        setLoading(false)
        getData()
        toast.success("Account added successfully.")
      }).catch((err) => {
        setLoading(false)
        toast.error("Account cannot be added!", err.message)
      })

      setVisi(false)
    }
  }

  return (
    <React.Fragment>
      <Header head={"Assign Roles"} placeholder={"Search by username"} search getSearchValue={(value) => setSearchVal(value)} paraDash={"Assign the roles to different users."} />
      <ToastContainer />
      <Loader loading={loading} />
      <div className='main-div'>
        <div className='account-top'>
          <div className='account-flex'>
            <div className='account-heading'>
              <p className='account-para'>Roles</p>
            </div>
            <div className='account-button'>
              <div>
                <button className={filterToggle ? "filter-btn-sel" : "filter-btn"} type='text' style={{ cursor: "pointer" }} onClick={addAccountHandler} >Add Account</button>
              </div>
              <div className='account-icon' style={{ cursor: "pointer" }} onClick={() => getData()}>
                <TiArrowForward />
              </div>
            </div>
          </div>
          <div className='last-thirty-days'>
            <DatePicker selected={startDate} onChange={(e) => filterData(e)} placeholderText={'dd/mm/yyyy'} />
            <div className='delTi' onClick={delDate}><TiDelete /></div>
          </div>
          <div className='table'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Created at</th>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.length !== 0 ? allUsers
                  .slice((page - 1) * elem, (page * elem) + elem).reverse().filter((val) => {
                    if (searchVal == "") {
                      return val
                    } else if (val?.userName?.toUpperCase()?.includes(searchVal.toUpperCase())) {
                      return val
                    }
                  }).map((elem, index) =>
                    <tr key={index}>
                      <td>{moment(elem?.createdAt).format("D-MMM-YYYY")}</td>
                      <td>{elem?._id?.substring(0, 7).concat("...")}</td>
                      <td>{elem?.userName?.substring(0, 7)}</td>
                      <td>{elem?.email.substring(0, 15).concat("...")}</td>
                      <td>
                        <p className={elem?.type === "admin" ? "admin" : elem?.type === "financial manager" ? "financ" : elem?.type === "inspection manager" ? "insepec" : null} >{elem?.type === "admin" ? "Admin" : elem.type === "financial manager" ? "Financial Manager" : "Inspection Manager"}</p>
                      </td>
                      <td>
                        <div className='flex-edit-del'>
                          <div className='eye' onClick={() => viewModel(elem)}><AiOutlineEye /></div>
                          <div className='edit' onClick={() => modalHandler(elem)}><FiEdit /></div>
                          <div className='del' onClick={() => deleteHandler(elem._id)}><RiDeleteBinLine /></div>
                        </div>
                      </td>
                    </tr>
                  ) : null
                }
              </tbody>
            </table>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </div>
        </div>
        <Modal
          open={visible ? true : false}
          onClose={() => setVisible(false)}
          getUserNamepro={(item) => setUserName(item)}
          getRolesStatus={(item) => setRoles(item)}
          userName={userName}
          roles={roles}
          email={email}
          onUpdate={() => editHandler()}
          asignRole
        />
        <ViewModal
          open={view ? true : false}
          onClose={() => setView(false)}
          Name={viewName}
          AccountType={viewType}
          Status={viewStatus}
          email
          srcImg={srcImage}
        />
        <AddAccountsModal
          open={visi ? true : false}
          onClose={() => setVisi(false)}
          onSend={onAddAccounts}
          getUserName={(v) => setPersonUserName(v)}
          getEmail={(v) => setPersonEmail(v)}
          getPassword={(v) => setPersonPassword(v)}
          getType={(v) => setPersonType(v)}
        />
      </div>
    </React.Fragment>
  )
}

export default AssignRoles;
