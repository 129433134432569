import firebase from 'firebase/compat/app';
import { getStorage, } from "firebase/storage";

import { initializeApp } from 'firebase/app';


const firebaseConfig = {
  apiKey: "AIzaSyAqUWUj_2-4kHGbGIClG_XAejPCEt3QLeg",
  authDomain: "react-with-firebase-21ca5.firebaseapp.com",
  projectId: "react-with-firebase-21ca5",
  storageBucket: "react-with-firebase-21ca5.appspot.com",
  messagingSenderId: "668552071227",
  appId: "1:668552071227:web:cc747831a7a96eba8bb41e",
  measurementId: "G-6403LQ3P11"
}; 
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
 