import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";


const TotalSpentChart = ({spentData}) => {
    console.log('spentData: ', spentData);

    const [data,setData] = useState([]);
    const DATE_FORMAT = "Do";

    useEffect(()=>{
        getData()
    },[spentData])

    const getData = async () =>{
        const newData = Array(30).fill(moment().format(DATE_FORMAT)).reduce((acc,item,i)=>{
            const name = moment().subtract(i,"days").format(DATE_FORMAT)
            acc.push({
                name:name,
                This_Month: spentData?.currentMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc,item) => acc + item.totalPrice, 0),
                Last_Month: spentData?.previousMonth?.filter(i => moment(i.createdAt).format(DATE_FORMAT) === name).reduce((acc,item) => acc + item.totalPrice, 0)
            })
            return acc;
        },[])
        setData(newData.reverse());
    }


    return (
        <div style={{width:"100%" ,height:"100%"}}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data}
                    margin={{ top: 10, right: 30, left: 30, bottom: 60 }}>
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#2C99FC" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#303279" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#5F63F2" stopOpacity={0.3} />
                            <stop offset="95%" stopColor="#303279" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="name" axisLine={false} tickLine={false} interval={0} angle={-45} dy={5}/>
                    <YAxis hide dx={-15}/>
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Area type="monotone" dataKey="Last_Month" stroke="#5F63F2" strokeWidth={3} fillOpacity={0.4} fill="url(#colorUv)" />
                    <Area type="monotone" dataKey="This_Month" stroke="#2A575E" strokeWidth={3}  fillOpacity={0.4} fill="url(#colorPv)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default TotalSpentChart;
