import React from 'react';

const CustomerProfile = ({profileName,profileMail,onClick}) => {
    return (
        <div>
            <div className='profile-flex' onClick={onClick}>
                    <div className='profile-detail'>
                        <p className='profile-para'>{profileName}</p>
                        <p className='profile-paras'>{profileMail}</p>
                </div>
            </div>
            <div className='line'></div>
        </div>
    )
}

export default CustomerProfile;
