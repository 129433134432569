import React, { useEffect, useState } from 'react';
import Header from '../Componenets/Header';
import customer from "../assets/customer.png";
import exclamation from "../assets/exclamation-triangle.png";
import clock from "../assets/Clock.png";
import accept from "../assets/accept.png";
import Card from '../Componenets/Card';
import "../css/Escrow.css";
import { RiArrowUpFill } from "react-icons/ri";
import Esslipse from '../Componenets/Esslipse';
import ellipse from "../assets/Ellipse.jpg";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import BarCharts from "../Graphs/BarCharts";
import { data2 } from './StaticData';
import ell3 from "../assets/Ellipse3.jpg";
import Saloon from '../Componenets/Saloon';
import { editBooking, editTranaction, getAllStore, getEscrowTop, getTransaction, moneyEarned } from '../Path/Path';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Componenets/Loader';
import Pagination from '../Componenets/Pagination';
import moment from 'moment';
import { GrStatusGood } from 'react-icons/gr';
import swal from 'sweetalert';


const Escrow = (props) => {
  console.log('props: ', props);

  const [saloonData, setSaloonData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [escrowTop, setEscrowTop] = useState([]);
  const [money, setMoney] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getData()
  }, [])

  const elem = 4;

  const getData = async () => {
    setLoading(true)
    await getAllStore().then((res) => {
      setSaloonData(res)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong", err.message)
    })
    setLoading(true)
    await getEscrowTop().then((res) => {
      setEscrowTop(res)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong.", err.message)
    })
    setLoading(true)
    await moneyEarned().then((res) => {
      setMoney(res)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong.", err.message)
    })
    setLoading(true)
    await getTransaction().then((res) => {
      setTransactions(res.docs)
      console.log('setTransactions: ', setTransactions);
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong.", err.message)
    })

    setLoading(false)
  }


  const bookingHandler = (id) =>{
    console.log("id",id);
    let body ={
      status: "completed"
    }

    swal({
      title: "Are you sure?",
      text: "Are you sure to completed this request?",
      icon: "warning",
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          await editTranaction(body, id).then(async(res) => {
            await getTransaction().then((res) => {
              setTransactions(res.docs)
              setLoading(false)
            }).catch((err) => {
              setLoading(false)
              toast.error("Something went wrong.", err.message)
            })
        
            toast.success("Transaction completed successfully.")
            setLoading(false)
          }).catch((err) => {
            toast.warn("Transaction cannot be completed!")
          })
          swal("Approved!", "Completed successfully ", "success");
        }
      })
  }

  // const totalPage = parseInt((saloonData.length / 10) + 1)

  console.log("hsn",transactions);

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <ToastContainer />
      <Header head={"Escrow"} paraDash={"Get all information about the escrow."} />
      <div className='main-div'>
        <div className='dashboard-top'>
          <Card head="Total Contacts" num={escrowTop.totalContacts} span="+55%" source={accept} />
          <Card head="Total Customers" num={escrowTop.totalCustomer} span="+20%" source={customer} />
          <Card head="Pending Clearance" num={escrowTop.pendingClearance} span="-2%" source={clock} />
          {/* <Card head="Escrows Error" num="343" span="+5%" source={exclamation} /> */}
        </div>
        <div className='escrow-main'>
          <div className='bar-grap-chart'>
            <div className='main-bar'>
              <p className='area-para'>Money Earned-2022</p>
              {/* <div className='sale-flex nec'>
                <div className='up-down-arrow'><RiArrowUpFill /></div>
                <p className='area-progress'>8% more in 2022</p>
              </div> */}
              <div className='monthly'>
                <p className='mon-para'>Monthly</p>
                <div className='bef'>
                  <p className='bef-para'>arrival</p>
                  <p className='bef-paras'>spending</p>
                </div>
              </div>
              <div className='barChart'>
                <BarCharts moneyEar={money} />
              </div>
            </div>
          </div>
        </div>
        <div className='trans-main'>
          <div className='transaction'>
            <div className='act'><p className='area-para' style={{ marginBottom: "20px" }}>Transaction History</p></div>
            <div className='trans-table'>
              <table className='trans-tables'>
                <thead>
                  <tr>
                    <th>Saloon Name</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.reverse().map((item) =>
                    <tr>
                      <td>{item?.salonID?.name?.substring(0,7).concat("...")}</td>
                      <td>{item?.status}</td>
                      <td>{moment(item?.createdAt).format("D MMM YYYY")}</td>
                      <td >{item?.total?.toString().length > 4 ? item?.total?.toString().substring(0,4).concat("..."):item?.total?.toString()}</td>
                      <div className='' style={{display:"flex",justifyContent:"center",alignItems:"center",marginTop:"20px",color: "#2A5555"}}>
                            <div className='tick' onClick={() => bookingHandler(item._id)}><GrStatusGood /></div>
                          </div>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className='pag'>
            <div className='saloon'>
              <div className='salon'>
                <p className='area-para '>Saloon</p>
              </div>
              <div className='sho'>
              {
                !!saloonData.length && saloonData
                  .slice((page - 1) * elem, (page * elem) + elem).reverse().map((item, index) =>
                    <Saloon
                      imgSrc={item?.gallery[0]}
                      saPara={item?.name}
                      item={item}
                      salPara={item?.website?.split("//")[1]?.substring(0, 20)}
                    />
                  )

              }
              </div>
            </div>
            {/* <Pagination page={page} setPage={setPage} totalPage={totalPage} /> */}
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default Escrow;
