import React, { useEffect, useState } from 'react';
import "../css/Account.css";
import "../css/Table.css";
import moment from 'moment';
import Header from '../Componenets/Header';
import { TiArrowForward, TiDelete } from "react-icons/ti";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import Modal from '../Componenets/Modal';
import swal from "sweetalert";
import { connect } from 'react-redux';
import { deleteUser, editUser, getAllUsers } from '../Path/Path';
import Loader from '../Componenets/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Autocomplete, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import ClearIcon from "@material-ui/icons/Clear";
import Pagination from '../Componenets/Pagination';
import ViewModal from '../Componenets/ViewModal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsFillCalendarFill } from "react-icons/bs";


const Accounts = (props) => {

  const [page, setPage] = useState(1);
  const [view, setView] = useState(false)
  const [searchVal, setSearchVal] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [toggleT, setToggleT] = useState(true);
  const [visible, setVisible] = useState(false);
  const [accountType, setAccountType] = useState("")
  const [userName, setUserName] = useState("")
  const [status, setStatus] = useState("")
  const [selectedUser, setSelectedUser] = useState(null)
  const [id, setId] = useState("");
  const [type, setType] = useState('customer')
  const [loading, setLoading] = useState(false)
  const [filterToggle, setFilterToggle] = useState(false);
  const [viewName, setViewName] = useState("")
  const [viewType, setViewType] = useState("")
  const [viewStatus, setViewStatus] = useState("")
  const [startDate, setStartDate] = useState("");
  const [srcImage, setSrcImage] = useState("");

  const elem = 12;




  useEffect(() => {
    fetchData('customer')

  }, [])


  const delDate = async () => {
    setStartDate("")
    setLoading(true)
    await fetchData(type)
    setLoading(false)
  }

  const fetchData = async (type) => {
    setLoading(true)
    setFilterToggle(false)
    await getAllUsers().then((res) => {
      console.log('res: ', res);
      if (res.length > 0) {
        res = res?.filter((item, index) => item?.type === type)
        setAllUsers(res)
        setLoading(false)

      } else {
        toast.warn("No data found!");
        setLoading(false)
      }
    }).catch((err) => {
      toast.error("Something went wrong!");
      setLoading(false)
    })

  }

  // const dateChange = async (e) =>{

  //   await filterData(type);
  // }

  const filterData = async (e) => {
    setStartDate(e)
    setLoading(true)
    await getAllUsers().then((res) => {
      if (res.length > 0) {
        res = res?.filter((item, index) => item?.type === type)
        const result = res.filter((i) => moment(i.createdAt).format('DD/MM/YYYY') === moment(e).format('DD/MM/YYYY'))
        setAllUsers(result)
        setLoading(false)

      } else {
        toast.warn("No data found!");
        setLoading(false)
      }
    }).catch((err) => {
      toast.error("Something went wrong!");
      setLoading(false)
    })
  }

  console.log("startDate", startDate);



  const toggleTable = async () => {
    setToggleT(true)
    setFilterToggle(false)
    setType('customer')
    await fetchData('customer')
  }

  const toggleTableService = async () => {
    setToggleT(false)
    setFilterToggle(false)
    setType('service provider')
    await fetchData('service provider')
  }

  const modalHandler = (elem) => {
    setId(elem._id)
    setUserName(elem?.userName)
    setAccountType(elem?.type)
    setStatus(elem?.isActive ? 'Approved' : 'Pending')
    setSrcImage(elem?.image)
    setVisible(true)

  }

  const editHandler = async () => {

    if (!userName) {
      toast.warn('Enter user name')
    }
    else {
      let body = {
        userName: userName,
        type: accountType,
        isActive: status === "Approved" ? true : false
      }
      setLoading(true)
      await editUser(body, id).then(async (res) => {
        await fetchData(type)
        setUserName('')
        setAccountType('')
        setStatus('')
        setLoading(false)
        setVisible(false)
        toast.success("User Edit Successfully")
      }).catch((error) => {
        toast.warn("User cannot be edit.")
      })
    }

  }

  const deleteHandler = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure to delete this user?",
      icon: "warning",
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          await deleteUser(id)
          await fetchData(type)
          swal("Deleted!", "User deleted successfully ", "success");
        }
      }).catch((err) => {
        toast.error("User cannot be deleted.")
      })
  }

  const totalPage = parseInt((allUsers.length / 12) + 1)

  const viewModel = (elem) => {
    setView(true)
    setViewName(elem.userName);
    setViewType(elem.type)
    setViewStatus(elem.isActive ? "Approved" : "Pending")
  }



  console.log("searchVal", allUsers);


  return (
    <React.Fragment>
      <Header head={"Accounts"} placeholder={"Search by username"} search paraDash={"Details about buyer and seller accounts."} getSearchValue={(value) => setSearchVal(value)} />
      <ToastContainer />
      <Loader loading={loading} />
      <div className='main-div'>
        <div className='account-top'>
          <div className='account-flex'>
            <div className='account-heading'>
              <p className='account-para'>Accounts</p>
            </div>
            <div className='account-button'>
              <div className='customer-account-table'>
                <button type="text" style={{ cursor: "pointer" }} className={toggleT ? "selectBtn" : "Btn"} onClick={toggleTable}>Customer Accounts</button>
              </div>
              <div className='serviceProvider-account-table'>
                <button type="text" style={{ cursor: "pointer" }} className={!toggleT ? "selectBtn1" : "Btn1"} onClick={toggleTableService}>Service Providers</button>
              </div>
              <div className='account-icon' style={{ cursor: "pointer" }} onClick={() => fetchData(type)}>
                <TiArrowForward />
              </div>
            </div>
          </div>
          <div className='last-thirty-days'>
            <DatePicker selected={startDate} onChange={(e) => filterData(e)} placeholderText={'dd/mm/yyyy'} />
            <div className='delTi' onClick={delDate}><TiDelete /></div>
          </div>
          <div className='table'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Created at</th>
                  <th>ID</th>
                  <th>{type === 'service provider' ? 'Store Name' : 'UserName'}</th>
                  <th>Account Type</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allUsers.length !== 0 ? allUsers
                  .slice((page - 1) * elem, (page * elem) + elem).reverse().filter((val) => {
                    if (searchVal == "") {
                      return val
                    } else if (val?.userName?.toUpperCase()?.includes(searchVal.toUpperCase())) {
                      return val
                    }
                  }).map((elem, index) =>
                    <tr key={index}>
                      <td>{moment(elem?.createdAt).format("D-MMM-YYYY")}</td>
                      <td>{elem?._id?.substring(0, 7).concat("...")}</td>
                      <td>{elem?.userName?.substring(0, 7)}</td>
                      <td>{elem?.type}</td>
                      <td>
                        <p className={elem?.isActive === true ? "select" : elem?.isActive === false ? "select1" : elem?.isActive === "Disabled" ? "select2" : null}>{elem?.isActive === true ? 'Approved' : 'Pending'}</p>
                      </td>
                      <td>
                        <div className='flex-edit-del'>
                          <div className='eye' onClick={() => viewModel(elem)}><AiOutlineEye /></div>
                          <div className='edit' onClick={() => modalHandler(elem)}><FiEdit /></div>
                          <div className='del' onClick={() => deleteHandler(elem._id)}><RiDeleteBinLine /></div>
                        </div>
                      </td>
                    </tr>
                  ) : null
                }
              </tbody>
            </table>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
          </div>
        </div>
        <Modal
          open={visible ? true : false}
          onClose={() => setVisible(false)}
          userName={userName}
          accountType={accountType}
          status={status}
          onUpdate={() => editHandler()}
          checkService={type === 'service provider' ? 'service provider' : 'customer'}
          getUserNamepro={(item) => setUserName(item)}
          getAccountTypepro={(item) => setAccountType(item)}
          getStatuspro={(item) => setStatus(item)}
        />
        <ViewModal
          open={view ? true : false}
          onClose={() => setView(false)}
          Name={viewName}
          AccountType={viewType}
          Status={viewStatus}
          srcImg={srcImage}
        />
      </div>
    </React.Fragment>
  )
}

const mapStatetoProps = (state) => {
  return {
    user: state.user,
    accounts: state.accounts
  }
}

export default connect(mapStatetoProps, null)(Accounts);
