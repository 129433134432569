import React, { useState, useRef } from 'react';
import Header from '../Componenets/Header';
import "../css/Earning.css";
import { BsFillCalendar2Fill } from "react-icons/bs";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import format from "date-fns/format";
import { useEffect } from 'react';
import { addDays } from "date-fns";
import TransactionCard from '../Componenets/TransactionCard';
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import TotalPaymentChart from '../Graphs/TotalPaymentChart';
import ServiceProviderChart from '../Graphs/ServiceProviderChart';
import ProfitChart from '../Graphs/ProfitChart';
import { getAllSaloonSales, getCustomersPayment, getTransaction, ourProfit } from '../Path/Path';
import Loader from '../Componenets/Loader';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import Pagination from '../Componenets/Pagination';

const Earnings = () => {

  const [paymentData, setPaymentData] = useState([])
  const [providerData, setProviderData] = useState([])
  const [loading, setLoading] = useState(false);
  const [ourProfits, setOurProfits] = useState([])
  const [yourTransaction,setYourTransaction] = useState([])
  const [page, setPage] = useState(1);

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    setLoading(true)
    await getCustomersPayment().then((res) => {
        setPaymentData(res)
        setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong", err.message);
    })
    setLoading(true)
    await getAllSaloonSales().then((res) => {
        setProviderData(res)
        setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong", err.message);
    })
    setLoading(true)
    await ourProfit().then((res) => {
        setOurProfits(res)
        setLoading(false)
    }).catch((err) => {
      setLoading(false)
      toast.error("Something went wrong", err.message);
    })

    setLoading(true)
    await getTransaction().then((res)=>{
        setYourTransaction(res.docs)
        setLoading(false)
    }).catch((err)=>{
      setLoading(false)
      toast.error("Something went wrong" , err.message)
    })


    setLoading(false)
  }

  const elem = 4;

  const totalPage = parseInt((yourTransaction.length / 4) + 1)

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <ToastContainer />
      <Header head={"Earnings"} paraDash={"Get all information about the earnings."} />
      <div className='main-div'>
        <div className='flex-earn'>
          <div className='our-transactions'>
            <div className='transaction-flex'>
              <p className='area-para'>Your Transactions</p>
            </div>
            <div className='earning-bottom'>
              {
                 yourTransaction.length !== 0 ? yourTransaction
                 .slice((page - 1) * elem, (page * elem) + elem).reverse().map((item, index) =>(
                  <TransactionCard
                    proName={item?.salonID?.name}
                    dates={moment(item?.createdAt).format("D-MMM-YYYY")}
                    percent={item?.status === "completed"? item?.total : "Pending"}
                  />
                ))
                : null
              }

            </div>
            <Pagination page={page} setPage={setPage} totalPage={totalPage} />
            {/* <div className='earning-pagi-wrap'>
              <button className='pgn-btn-prev' >
                <IoMdArrowDropleft className='prev-icon' />
              </button>
              <button className={'pgn-btn'}  >1</button>
              <button className={'pgn-btn'}  >2</button>
              <button className={'pgn-btn'}  >3</button>
              <button className={'pgn-btn'}  >4</button>
              <button className='pgn-btn-next' >
                <IoMdArrowDropright className='prev-icon' />
              </button>
            </div> */}
          </div>
        </div>
        <div className='payment-raing'>
          <div className='saloon-rating-flex'>
            <p className='area-para diff'>Total Payments of Customers</p>
            <div className='this-last-month'>
              <p className='this-month'>This Month</p>
              <p className='last-month'>Last Month</p>
            </div>
          </div>
          <div className='payment-ration-chart'>
            <TotalPaymentChart myPayments={paymentData} />
          </div>
        </div>
        <div className='service-sales'>
          <div className='saloon-rating-flex'>
            <p className='area-para'>Service Providers Sales</p>
            <div className='this-last-month'>
              <p className='thiss-month'>This Month</p>
              <p className='lasts-month'>Last Month</p>
            </div>
          </div>
          <div className='service-sales-chart'>
            <ServiceProviderChart myPrvider={providerData} />
          </div>
        </div>
        <div className='total-spen-chart'>
          <div className='spent'>
            <div className='saloon-rating-flex'>
              <p className='area-para'>Our Profit</p>
              <div className='this-last-month'>
                <p className='thiss-month'>This Month</p>
                <p className='lasts-month'>Last Month</p>
              </div>
            </div>
          </div>
          <div className='profit-charts'>
            <ProfitChart ourPro={ourProfits} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Earnings;
