import { io } from "socket.io-client";
import { BASE_URL } from "../Path/Path";


const socket = io(BASE_URL);

socket.onAny((event, ...args) => {
    console.log("%csocket: " + event, '  color: red');

});




export default socket;