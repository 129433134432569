import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{border:"1px solid gray",backgroundColor:"#fff",padding:"10px"}}>
          <p className="label" style={{marginBottom:"5px"}}>{`${label}`}</p>
          <p className="desc">Users: {payload[0].value}</p>
        </div>
      );
    }
  
    return null;
  };

const BarGraph = ({userData}) => {

    const[data,setData] = useState([]);
    const DATE_FORMAT = "ddd";

    useEffect(()=>{
        getData()
    },[userData])

    const getData = async () =>{
        const newData = Array(7).fill(moment().format(DATE_FORMAT)).reduce((acc,item,i)=>{
            const name = moment().subtract(i,"days").format(DATE_FORMAT);
            acc.push({
                name:name,
                Users : userData?.currentWeek?.filter((i) => moment(i.createdAt).format(DATE_FORMAT) === name).length
            })

            return acc;

        },[])

        setData(newData.reverse());
    }

    return (
        <ResponsiveContainer width="100%" height="100%" >
            <BarChart
                data={data}
                margin={{
                    top: 10,
                    right: 0,
                    left: 20,
                    bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="7 3" vertical={false} />
                <XAxis
                    dy={5}
                    dataKey="name"
                    tickLine={false}
                    stroke="#fff"
                    // tick={{fontSize :12}}
                    axisLine={{ stroke: '#D8D8D8' }}
                    interval={0}
                />
                <YAxis
                   hide
                />
                <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip/>}
                />

                <Bar dataKey="Users" fill="#fff" barSize={10} radius={[10, 10, 0, 0]} />
            </BarChart>
        </ResponsiveContainer>
    );
}


export default BarGraph;