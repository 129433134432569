import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';
import "../css/Modal.css";
import DropDown from './DropDown';
import "../css/DropDown.css";
import { TiDelete } from "react-icons/ti";


const AddAccountsModal = ({
    open,
    onClose,
    onSend,
    getUserName,
    getEmail,
    getPassword,
    getType
}) => {
    return (
        <Dialog aria-labelledby='dialog-title' aria-describedby='dialog-description' open={open} >
            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px", alignItems: "center" }}>
                <h2 className='h2-head' style={{ fontSize: "2.5rem" }}>Add Accounts</h2>
                <div className='close-btn' style={{ margin: "-10px", fontSize: "3rem", cursor: "pointer" }} onClick={onClose}><TiDelete /></div>
            </div>
            <div className='line'></div>
            <DialogContent>
                <div className="modal-main" style={{ width: "500px" }} >
                    <label htmlFor='username'>Username</label>
                    <input type="text" id='username' placeholder='Username' onChange={(e)=> getUserName(e.target.value)}  />
                </div>
                <div className="modal-main" style={{ width: "500px" }} >
                    <label htmlFor='Email'>Email</label>
                    <input type="email" id='Email' placeholder='Email' onChange={(e) => getEmail(e.target.value)}  />
                </div>
                <div className="modal-main">
                    <label htmlFor='Password'>Password</label>
                    <input type="text" id='Password' placeholder='Password' onChange={(e) => getPassword(e.target.value)} />
                    <div>
                        <div className='drop'>
                            <label>Status</label>
                            <DropDown
                                options={[
                                    { label: 'Admin', value: 'admin' },
                                    { label: 'Financial Manager', value: 'financial manager' },
                                    { label: 'Inspection Manager', value: 'inspection manager' },
                                ]}
                                onChange={(e) => getType(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <div className='but-flex'>
                <div className='cancel'>
                    <button onClick={onClose}>Cancel</button>
                </div>
                <div className='save-changes'>
                    <button onClick={onSend}>Save Changes</button>
                </div>
            </div>
        </Dialog>
    )
}

export default AddAccountsModal;
