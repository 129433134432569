import React from 'react';

const DropDown = ({  value, options, onChange }) => {
  return (
    <label>
    <select value={value} onChange={onChange}>
      {options.map((option) => (
        <option value={option.value} className="option"  >{option.label}</option>
      ))}
    </select>
  </label>
  )
}

export default DropDown;
